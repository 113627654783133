import React from 'react';

// import { Session } from '~/components';

// import { useAuth } from '~/hooks/auth';

interface RouteProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RouteProps> = ({ children }) => {
  // const { user, signOut } = useAuth();

  // const checarExpiracao = useCallback(() => {
  //   const token = localStorage.getItem('@SisautERP:token');
  //   if (!token || token == null) {
  //     localStorage.removeItem('@SisautERP:token');
  //     localStorage.removeItem('@SisautERP:user');
  //     signOut();
  //   }
  // }, [signOut]);

  // useEffect(() => {
  //   setTimeout(() => {
  //     checarExpiracao();
  //     window.addEventListener('storage', checarExpiracao, false);
  //   }, 3000);
  // }, [checarExpiracao]);

  // if (!user) {
  //   return <Session />;
  // }

  return <>{children}</>;
};

export default RequireAuth;
