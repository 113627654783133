import React from 'react';

import { Container } from './styles';

import { LineProps } from "./interface";

const GridLine: React.FC<LineProps> = ({ children, stick, ...props }) => {
  return <Container stick={stick} {...props}>{children}</Container>;
};

export default GridLine;
