import React from 'react';

import {
  LineChart,
  Line,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { Container } from './styles';

import { Graph1Props } from './interface';
import { maskPrice } from '~/helpers/money';

const Graph1: React.FC<Graph1Props> = ({ data }) => {
  return (
    <Container>
      <ResponsiveContainer>
        <LineChart
          data={data}
          margin={{
            top: 0,
            right: 40,
            left: 40,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="data" stroke={'#ccc'} angle={0} textAnchor="end" tickMargin={10} dx={25} />
          <Tooltip
            contentStyle={{
              borderRadius: 5,
              borderColor: '#fff',
            }}
            labelStyle={{ color: '#333' }}
            formatter={(value: number) => maskPrice(value, true)}
            cursor={{fill: 'rgba(255, 255, 255, 0.08)'}}
          />
          <Line
            type="monotone"
            dataKey="total"
            stroke="#ffa366"
            strokeWidth={4}
            activeDot={{ r: 14 }}
          />
        </LineChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default Graph1;
