import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  float: left;
  width: 100%;
  margin-top: 2rem;

  main {
    display: flex;
    flex-direction: row;
    background: ${({ theme }) => theme.COLORS.COUNTER_BG};
    margin: 0;
    float: left;
    width: 100%;
    border-radius: 0.4rem;
    padding: 1rem;

    div {
      display: flex;
      flex-direction: column;
      flex: 1;

      h1 {
        color: ${({ theme }) => theme.COLORS.COUNTER_TEXT};
        font-size: 1.2rem;
      }
      h2 {
        color: ${({ theme }) => theme.COLORS.COUNTER_TEXT};
        font-size: 1rem;
        opacity: 0.5;
        font-weight: normal;
      }
    }
  }

  button {
    background: none;
    margin: 0px;
    padding: 0px;
  }

  @media (max-width: 1024px) {
  }
`;
