import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
`;

export const Content = styled.div`
  width: 100%;
  float: left;
  max-width: 60rem;
  background: ${({ theme }) => theme.COLORS.AREA_BG};
  padding: 2rem;

  border-radius: 1rem;

  margin: 2rem 0 0rem 2rem;

  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.03);
  border-top: 0px;
  border-left: 0px;

  @media (max-width: 1024px) {
    border-radius: 0;
    margin: 0px;
    padding: 1rem;
  }
`;
