import React from 'react';

import { ImPlus } from 'react-icons/im';

import { Container } from './styles';

import { PlusProps } from './interface';

const Plus: React.FC<PlusProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick}>
      <ImPlus size={26} color="#fff" />
    </Container>
  );
};

export default Plus;
