import React from 'react';
import { useNavigate } from 'react-router-dom';

// import { FaSearch } from 'react-icons/fa';
// import { AiFillCloseSquare, AiOutlineClose, AiOutlineFilter } from 'react-icons/ai';
// import { BiSlider } from 'react-icons/bi';
import { LuFilterX, LuFilter } from 'react-icons/lu';

// import { Content, Search, Button, Input } from './styles';
import { Content, Button, ButtonVoltar } from './styles';

import { BreadcrumbProps } from './interface';

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  onToggleFilters,
  isSearch,
  onSearch,
  filterStatus,
  title,
  subtitle,
}) => {
  const navigate = useNavigate();
  // const [searchStr, setSearchStr] = useState<string>('');

  // const onSubmit = (e: React.FormEvent<Element>) => {
  //   e.preventDefault();
  //   if (onSearch) {
  //     onSearch(searchStr);
  //   }
  // };

  // const onKeyDownInput = (e: React.KeyboardEvent<HTMLElement>) => {
  //   if (e.key === 'Enter') {
  //     e.preventDefault();
  //     if (onSearch) {
  //       onSearch(searchStr);
  //     }
  //   }
  // };

  return (
    <Content>
      <h1>{title}</h1>
      {onToggleFilters && (
      <>
        <Button onClick={onToggleFilters}>
          {filterStatus ? (
            <LuFilterX size={32} color="#fff" />
          ) : (
            <LuFilter size={32} color="#fff" />
          )}
        </Button>
      </>)}
      <ButtonVoltar onClick={() => navigate(-1)}>
        Voltar
      </ButtonVoltar>
    {/* 
      {isSearch ? (
        <main>
          <Search onSubmit={onSubmit}>
            <div>
              <Input
                placeholder="Busca"
                value={searchStr}
                onChange={e => setSearchStr(e.target.value.toUpperCase())}
                onKeyDown={onKeyDownInput}
                autoCapitalize='characters'
              />
              {searchStr ? (
                <Button onClick={() => setSearchStr('')}>
                  <AiFillCloseSquare size={22} color={'#fff'} />
                </Button>
              ) : null}
            </div>
            <Button type="submit">
              <FaSearch size={22} color={'#fff'} />
            </Button>
          </Search>
          <Button onClick={onToggleFilters}>
            {filterStatus ? (
              <LuFilterX size={32} color="#fff" />
            ) : (
              <LuFilter size={32} color="#fff" />
            )}
          </Button>
        </main>
      ) : null} */}
    </Content>
  );
};

export default Breadcrumb;
