import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
`;

export const Background = styled.div`
  float: left;
  width: 100%;
  background: ${({ theme }) =>
    `linear-gradient(to bottom,${theme.COLORS.GRADIENT_COLOR_1}, ${theme.COLORS.GRADIENT_COLOR_2})`};
  background-size: 150% 150%;
  position: fixed;
  height: 100%;
`;

const CirclesAnimation = keyframes`  
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);   
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%); 
    opacity: 0;
  }

`;

export const Content = styled.div`
  width: 100%;
  float: left;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 20;
`;

export const Circles = styled.ul`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.4;

  li {
    position: absolute;
    top: 80vh;
    left: 45vw;
    width: 10px;
    height: 10px;

    transform-origin: top left;
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    animation: ${CirclesAnimation} 12s ease-in forwards infinite;

    border-style: solid;
    border-width: 2px;
    border-color: ${({ theme }) => theme.COLORS.GRADIENT_CIRCLE};

    &:nth-child(2) {
      animation-delay: 2s;
      left: 25vw;
      top: 40vh;
    }

    &:nth-child(3) {
      animation-delay: 4s;
      left: 75vw;
      top: 50vh;
    }

    &:nth-child(4) {
      animation-delay: 6s;
      left: 90vw;
      top: 10vh;
    }

    &:nth-child(5) {
      animation-delay: 8s;
      left: 10vw;
      top: 85vh;
    }

    &:nth-child(6) {
      animation-delay: 10s;
      left: 50vw;
      top: 10vh;
    }
  }
`;
