import * as yup from 'yup';

const schema = yup.object({
  empresa: yup.string().required('Informe a Empresa'),
  usuario: yup.string().required('Informe o usuário'),
  senha: yup
    .string()
    .required('Informe a senha')
    .min(3, 'A senha no mínimo 3 dígitos'),
});

export default schema;
