import 'styled-components';

import light from './light';

const dark: typeof light = {
  ...light,
  COLORS: {
    ...light.COLORS,

    TEXT: '#FFF',
    BUTTON_BG: '#666',

    SESSION_TITLE: '#fff',
    SESSION_SUBTITLE: '#ccc',
    SESSION_BACK_BG: '#000',
    SESSION_BACK_BG_HOVER: '#333',
    SESSION_BACK_TEXT: '#FFF',

    GRADIENT_COLOR_1: '#444',
    GRADIENT_COLOR_2: '#555',
    GRADIENT_CIRCLE: 'rgba(255,255,255,0.03)',

    BAR_CONTAINER_BG: 'rgba(255,255,255,0.1)',
    BAR_PROGRESS_BG: '#598d4f',

    MENU_BG: '#333',
    MENU_TEXT: '#fff',
    MENU_LINK_BG: 'rgba(0,0,0,0.02)',
    MENU_LINK_BG_HOVER: '#666',
    MENU_LINK_TEXT: '#fff',
    MENU_LINK_TEXT_HOVER: '#FFF',
    MENU_IC: '#fff',
    MENU_VERSION: '#666',

    HEADER_BG: '#333',
    HEADER_TEXT: '#FFF',
    HEADER_IC: '#fff',
    HEADER_SEARCH_COLOR: '#333',
    HEADER_BUTTON_BG: '#2bc48a',
    HEADER_BUTTON_TEXT: '#FFF',
    HEADER_BUTTON_BG_HOVER: '#333',
    HEADER_BUTTON_TEXT_HOVER: '#fff',

    BREADCRUMB_BG: '#333',

    FILTERS_BG: '#333',

    FINDER_BG: '#333',
    FINDER_TEXT: '#FFF',

    CARD_BG: '#333',
    CARD_BG_ACTIVE: '#222',
    CARD_LABEL: '#666',
    CARD_VALUE: '#fff',

    COUNTER_BG: '#666',
    COUNTER_TEXT: '#FFF',

    TOTALS_ANCHOR_BG: '#608b5e',
    TOTALS_RESUME_BG: '#666',
    TOTALS_TEXT: '#FFF',
    TOTALS_CLOSE_BG: '#333',

    PAGINATION_BG: '#333',

    GRID_BG: '#333',
    GRID_HEAD_ITEM_BG: '#666',
    GRID_HEAD_ITEM_TEXT: '#fff',
    GRID_ITEM_TEXT: '#fff',
    GRID_ITEM_TEXT_BOLD: '#fff',
    GRID_MENU_IC: '#fff',

    MODE_BG: '#333',
    MODE_BUTTON_BG: '#333',
    MODE_BUTTON_BG_ACTIVE: '#666',
    MODE_BUTTON_TEXT: '#FFF',

    DASH_CARD_BG: '#333',
    DASH_CARD_TAG: '#666',

    PLUS_BG: '#608b5e',
    PLUS_BG_HOVER: '#608b3e',

    AREA_BG: '#333',
  },
};

export default dark;
