import React from 'react';

import { useAdmin } from '~/hooks/admin';

import Admin from '~/pages/Admin';

interface RouteProps {
  children: React.ReactNode;
}

const RequireAuth: React.FC<RouteProps> = ({ children }) => {
  const { token } = useAdmin();

  if (!token) {
    return <Admin />;
  }

  return <>{children}</>;
};

export default RequireAuth;
