import styled from 'styled-components';

export const Title = styled.div`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.COLORS.TEXT};
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  margin-bottom: 2rem;
  align-self: flex-start;
  float: left;
  display: flex;
  font-weight: 700;
  padding: 1rem 2rem;

  @media (max-width: 1024px) {
    font-size: 1rem;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  float: left;
  margin-bottom: 2rem;

  .user {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    align-items: flex-start;
    padding: 0rem 0;

    h2 {
      font-size: 1.4rem;
      color: ${({ theme }) => theme.COLORS.TEXT};
      margin-bottom: 0.5rem;

      span {
        color: ${({ theme }) => theme.COLORS.SESSION_SUBTITLE};
      }

      @media (max-width: 1024px) {
        font-size: 1rem;
      }
    }
  }

  div {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    align-items: center;
    padding: 0rem 0;
  }

  h1 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.COLORS.TEXT};
  }
`;
