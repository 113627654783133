import React, { useState } from 'react';

import api from '~/services/api';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  Totals,
  Counter,
  List,
  Card,
  Loading,
  Pagination,
  Mode,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
} from '~/components';

import Filtros from './Filtros';

import { FormDataProps } from './Filtros/interface';

import { TotalProps } from '~/components/Totals/interface';
import FinancialReceive from '~/@types/FinancialReceive';

import qs from 'qs';
import { maskPrice } from '~/helpers/money';

const ContasAReceber: React.FC = () => {
  const [inLoad, setInLoad] = useState(false);

  const [listType, setListType] = useState('card');
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [totalsVisible, setTotalsVisible] = useState(false);

  const [filtersTitle, setFiltersTitle] = useState<string | null>(null);
  const [filtersSubtitle, setFiltersSubtitle] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);

  const [filters, setFilters] = useState<FormDataProps | null>(null);
  const [items, setItems] = useState<FinancialReceive[]>([]);
  const [totals, setTotals] = useState<TotalProps[]>([]);

  const onChangePage = (cPage: number) => {
    setItems([]);
    fetchResults(filters, cPage);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const closeFilters = () => {
    setFiltersVisible(false);
  };

  const toggleTotals = () => {
    setTotalsVisible(!totalsVisible);
  };

  const calcTotalFilters = (filtros: FormDataProps, totalReg: number) => {
    let inFilters: string[] = [];
    if (filtros.cliente) {
      inFilters.push(`cliente`);
    }
    if (filtros.tipo) {
      inFilters.push(`tipo`);
    }
    if (filtros.dtinicial) {
      inFilters.push(`data inicial`);
    }
    if (filtros.dtfinal) {
      inFilters.push(`data final`);
    }

    let message = null;
    if (inFilters.length) {
      const last = inFilters.pop();
      if (inFilters.length) {
        message = `Filtrando por: ${inFilters.join(', ')} e ${last}`;
      } else {
        message = `Filtrando por: ${last}`;
      }
    }

    setFiltersTitle(message);
    setFiltersSubtitle(`${totalReg} registros encontrados.`);
  };

  const onApplyFilters = async (filtros: FormDataProps) => {
    setFilters(filtros);
    fetchResults(filtros, 1);
  };

  const fetchResults = async (filtros: FormDataProps | null, cPage: number) => {
    try {
      setCurrentPage(cPage);

      if (cPage === 1) {
        setTotalPages(0);
        setTotalRegisters(0);
        setTotals([]);
        setItems([]);
      }

      setInLoad(true);

      let params = null;
      if (filtros) {
        params = qs.stringify({
          client: filtros.cliente,
          dateFinal: filtros.dtfinal,
          dateInitial: filtros.dtinicial,
          type: filtros.tipo,
          page: cPage,
          perPage: 20,
        });
      }

      const response = await api.get(
        `/finantial/receive${params ? `?${params}` : ''}`,
      );

      const { data } = response;

      const { data: items, total, pages, totalizadores } = data;
      if (items && items.length) {
        setItems(items);
        setTotalRegisters(total);
        setTotalPages(pages);
        setTotals([
          ...Object.getOwnPropertyNames(totalizadores).map(prop => ({
            label: `${prop}`,
            value: `${maskPrice(totalizadores[prop])}`,
          })),
        ]);
      }

      if (filtros) {
        calcTotalFilters(filtros, total);
      }

      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onReset = () => {
    setFiltersVisible(true);
  };

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Contas à Receber" />
          <Breadcrumb
            title="Contas à Receber"
            isSearch
            onSearch={(searchStr: string) => null}
            onToggleFilters={toggleFilters}
            filterStatus={filtersVisible}
          />
          <Filtros
            visible={filtersVisible}
            onApplyFilters={onApplyFilters}
            closeFilters={closeFilters}
          />

          {filtersTitle && filtersSubtitle ? (
            <Counter
              title={filtersTitle}
              subtitle={filtersSubtitle}
              onReset={onReset}
            />
          ) : null}

          {inLoad && <Loading />}

          {items.length ? (
            <Mode mode={listType} changeMode={mode => setListType(mode)} />
          ) : null}

          {listType === 'card' ? (
            <List>
              {items.map(item => (
                <Card
                  key={item.sequencia}
                  lines={[
                    [
                      {
                        label: 'Número:',
                        value: `${item.sequencia}`,
                      },
                      {
                        label: 'Valor R$:',
                        value: `${maskPrice(Number(item.valor))}`,
                      },
                      {
                        label: 'Vencimento:',
                        value: `${item.vencimento}`,
                      },
                    ],
                    [
                      {
                        label: 'Cliente:',
                        value: `${item.codcli} - ${item.cliente}`,
                      },
                    ],
                    [
                      {
                        label: 'Documento:',
                        value: `${item.documento}`,
                      },
                      {
                        label: 'Histórico:',
                        value: `${item.historico}`,
                      },
                    ],
                    [
                      {
                        label: 'Forma Pagto:',
                        value: `${item.formaPagto}`,
                      },
                      {
                        label: 'Pagamento:',
                        value: `${item.pagamento}`,
                      },
                      {
                        label: 'Valor Pago R$:',
                        value: `${maskPrice(Number(item.valorpago))}`,
                      },
                    ],

                    [
                      {
                        label: 'Funcionário:',
                        value: `${item.cdFuncionario} - ${item.dsFuncionario}`,
                      },
                    ],
                    [
                      {
                        label: 'Funcionário da Baixa:',
                        value: item.cdFuncionarioBaixa !== '0' && Number(item.valorpago) > 0 ? `${item.cdFuncionarioBaixa} - ${item.dsFuncionarioBaixa}` : 'NENHUM',
                      },
                    ],
                  ]}
                />
              ))}
            </List>
          ) : (
            <>
              {items.length ? (
                <Grid>
                  <GridLine stick>
                    <GridHeadItem title="Sequência" width={100} />
                    <GridHeadItem title="Cliente" />
                    <GridHeadItem title="Historico" />
                    <GridHeadItem title="Valor" />
                    <GridHeadItem title="Valor Pago" />
                    <GridHeadItem title="Vencimento" />
                    <GridHeadItem title="Pagamento" />
                    <GridHeadItem title="Lançamento" />
                    <GridHeadItem title="Documento" />
                    <GridHeadItem title="Parcela" />
                    <GridHeadItem title="Funcionário" />
                    <GridHeadItem title="Func [BAIXA]" />
                  </GridLine>

                  {items.map(item => (
                    <GridLine key={item.sequencia}>
                      <GridItem width={100}>
                        <small>{item.sequencia}</small>
                      </GridItem>
                      <GridItem>
                        <strong>{item.cliente}</strong>
                      </GridItem>
                      <GridItem>{item.historico}</GridItem>
                      <GridItem>{item.valor}</GridItem>
                      <GridItem>{item.valorpago}</GridItem>
                      <GridItem>{item.vencimento}</GridItem>
                      <GridItem>{item.pagamento}</GridItem>
                      <GridItem>{item.dataLancamento}</GridItem>
                      <GridItem>{item.documento}</GridItem>
                      <GridItem>{item.parcelanvenda}</GridItem>
                      <GridItem>
                        {item.cdFuncionario}: {item.dsFuncionario}
                      </GridItem>
                      <GridItem>
                        {item.cdFuncionarioBaixa}: {item.dsFuncionarioBaixa}
                      </GridItem>
                    </GridLine>
                  ))}
                </Grid>
              ) : null}
            </>
          )}

          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRegisters={totalRegisters}
              onChangePage={onChangePage}
            />
          ) : null}

          <Totals
            visible={totalsVisible}
            onToggle={toggleTotals}
            items={totals}
          />
        </Container>
      </Gradient>
    </>
  );
};

export default ContasAReceber;
