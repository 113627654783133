const maskPrice = (price: any, currencyDisplay = false, prefix = '', sufix = '') => {
  if (!price || price === undefined) {
    price = 0.0;
  }

  let formato = { minimumFractionDigits: 2 , style: 'currency', currency: 'BRL' };
  let formattedPrice = parseFloat(price).toLocaleString('pt-BR', formato);
  if (!currencyDisplay) {
    formattedPrice = formattedPrice.replace('R$', '');
  }

  formattedPrice = prefix + formattedPrice + sufix;

  return formattedPrice;

};

const toMoney = (price: string) => {
  if (!price || price === undefined) {
    return 0;
  }
  let _price = `${price}`;
  _price = _price.replace('R$', '');
  _price = _price.split('.').join('');
  _price = _price.replace(',', '.');
  _price = _price.trim();
  let _price_number = parseFloat(_price);
  return _price_number;
};

export { maskPrice, toMoney };