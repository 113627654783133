import React, { useState } from 'react';

import { AiFillEyeInvisible, AiFillEye, AiFillLock } from 'react-icons/ai';
import { BiSolidUser } from 'react-icons/bi';

import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import { Button, Stack, InputAdornment } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';

import { toast } from 'react-toastify';

import logoImg from '~/assets/logo.png';

import { useAdmin } from '~/hooks/admin';

import { Gradient, Container, Loading } from '~/components';

import { Content, Header, Center, Footer } from './styles';

import { FormDataProps } from './interface';

import schema from './schema';

const initialValues: FormDataProps = {
  senha: '',
  usuario: '',
};

const Admin: React.FC = () => {
  const { signIn } = useAdmin();
  const navigate = useNavigate();
  const { COLORS } = useTheme();

  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlerSignUp = (formData: FormDataProps) => {
    return new Promise((resolve, reject) => {
      const handler = async () => {
        try {
          const { usuario, senha } = formData;

          if (usuario !== 'sisaut' || senha !== 'Bd@455312!') {
            throw new Error('Falha au autenticação');
          }

          toast.success(`Bem vindo ao setor administrativo!`);
          const token = 'isAdmin';
          signIn(token);
          navigate('/empresas');
          resolve(true);
        } catch (e) {
          toast.error(`${(e as Error).message}`);
          resolve(true);
        }
      };
      handler();
    });
  };

  return (
    <Gradient>
      <Container>
        <Content>
          <Header>
            <div>
              <img
                src={logoImg}
                alt="Sisaut Gestor"
                title="Sisaut Gestor | 0.0.1"
              />
              <h1>ÁREA ADMINISTRATIVA</h1>
            </div>
          </Header>

          <Center>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                await handlerSignUp(values);
                setSubmitting(false);
              }}
              validationSchema={schema}
            >
              {({ submitForm, isSubmitting }) => (
                <Form style={{ width: '100%', maxWidth: '30rem' }}>
                  <Stack flexDirection={'column'} width={'100%'} mb={'1.5rem'}>
                    <Field
                      component={TextField}
                      label="Usuário"
                      name="usuario"
                      type="text"
                      variant="outlined"
                      InputProps={{
                        placeholder: 'Usuário',
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ outline: 'none' }}
                          >
                            <BiSolidUser size={22} color="#fff" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  <Stack flexDirection={'column'} width={'100%'} mb={'1.5rem'}>
                    <Field
                      component={TextField}
                      label="Senha"
                      name="senha"
                      type={passwordVisible ? 'text' : 'password'}
                      variant="outlined"
                      InputProps={{
                        placeholder: 'Senha',
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ outline: 'none' }}
                          >
                            <AiFillLock size={22} color="#fff" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            style={{ outline: 'none' }}
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? (
                              <AiFillEye size={22} color="#fff" />
                            ) : (
                              <AiFillEyeInvisible size={22} color="#fff" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Stack>

                  <Stack flexDirection={'column'} width={'100%'}>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      disableElevation
                      onClick={submitForm}
                      variant="contained"
                      size="large"
                      type="submit"
                      style={{
                        fontSize: '1.4rem',
                        fontWeight: '700',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        background: COLORS.BUTTON_BG,
                      }}
                    >
                      ACESSAR
                    </Button>
                  </Stack>

                  {isSubmitting && <Loading />}
                </Form>
              )}
            </Formik>
          </Center>

          <Footer>
            <small>Versão: 0.0.1</small>
          </Footer>
        </Content>
      </Container>
    </Gradient>
  );
};

export default Admin;
