import React, { useState, useEffect, useRef, useCallback } from 'react';

import { AiOutlineClose } from 'react-icons/ai';
import { FaWindowRestore } from 'react-icons/fa';

import api from '~/services/api';

import { Loading } from '~/components';

import { Container, Content } from './styles';

import OrderPayment from '~/@types/OrderPayment';
import OrderItem from '~/@types/OrderItem';
import { maskPrice } from '~/helpers/money';

import { ParamsProps } from './interface';

const Pedido: React.FC<ParamsProps> = ({ item, onClose }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [inLoad, setInLoad] = useState(true);

  const [items, setItems] = useState<OrderItem[]>([]);
  const [payments, setPayments] = useState<OrderPayment[]>([]);

  useEffect(() => {
    const fetchOrder = async (pId: string) => {
      try {
        setInLoad(true);

        const response = await api.get(`sales/search/${pId}`);
        const { data } = response;
        if (data) {
          const { itensSQL, formasPagtoSQL } = data;
          setItems(itensSQL);
          setPayments(formasPagtoSQL);
        }
        setInLoad(false);
      } catch (e) {
        setInLoad(false);
      }
    };

    if (item) {
      fetchOrder(item.sequencia);
    }
  }, [item]);

  const handlerShortCuts = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handlerShortCuts, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handlerShortCuts, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <Container>
      {inLoad ? (
        <Loading />
      ) : (
        <Content ref={ref}>
          <header>
            <h1>Pedido: {item.sequencia}</h1>
            <a
              href={`#/movimentacao/${item.sequencia}`}
              target="_blank"
              rel="noreferrer"
            >
              <FaWindowRestore size={28} color="#fff" />
            </a>
            <button type="button" onClick={onClose}>
              <AiOutlineClose size={38} color="#fff" />
            </button>
          </header>
          <main>
            <div>
              <p>
                <span>
                  <strong>Cliente:</strong>
                  <small>{item.Cliente}</small>
                </span>
                <span>
                  <strong>Vendedor</strong>
                  <small>{item.Vendedor1}</small>
                </span>
              </p>
              <p>
                <span>
                  <strong>Código:</strong>
                  <small>{item.sequencia}</small>
                </span>

                <span>
                  <strong>Data</strong>
                  <small>{item.Dataformated}</small>
                </span>
                <span>
                  <strong>Hora</strong>
                  <small>{item.Hora}</small>
                </span>

                <span>
                  <strong>Tipo</strong>
                  <small>{item.Tipo}</small>
                </span>
              </p>
              <p>
                <span>
                  <strong>Condição</strong>
                  <small>{item.Condicao}</small>
                </span>
                <span>
                  <strong>Custo</strong>
                  <small>{item.RSCusto}</small>
                </span>
                <span>
                  <strong>Líquido</strong>
                  <small>{item.RSTotalLiquido}</small>
                </span>
              </p>
              <p>
                <span>
                  <strong>Lucro</strong>
                  <small>{item.RSLucro}</small>
                </span>
                <span>
                  <strong>Total</strong>
                  <small>{item.RSTotal}</small>
                </span>
                <span>
                  <strong>Lucro (%)</strong>
                  <small>{item.PercLucro}</small>
                </span>
                <span>
                  <strong>Markup (%)</strong>
                  <small>{item.PercMarkup}</small>
                </span>
              </p>
            </div>
          </main>

          {items.length ? (
            <main>
              <h1>Itens do Pedido</h1>
              <div>
                {items.map(item => (
                  <p>
                    <span>
                      <strong>Código:</strong>
                      <small>{item.codpro}</small>
                    </span>
                    <span>
                      <strong>Descrição:</strong>
                      <small>{item.descricao}</small>
                    </span>
                    <span>
                      <strong>Quantidade:</strong>
                      <small>{maskPrice(item.qtd)}</small>
                    </span>
                    <span>
                      <strong>Valor Unitário:</strong>
                      <small>{maskPrice(item.vlUnitario, true)}</small>
                    </span>
                  </p>
                ))}
              </div>
            </main>
          ) : null}

          {payments.length ? (
            <main>
              <h1>Formas de Pagamento</h1>
              <div>
                {payments.map(payment => (
                  <p>
                    <span>
                      <strong>Código:</strong>
                      <small>{payment.codigo}</small>
                    </span>
                    <span>
                      <strong>Descrição:</strong>
                      <small>{payment.Descricao}</small>
                    </span>
                    <span>
                      <strong>Subtotal:</strong>
                      <small>{maskPrice(payment.SubTotalR$, true)}</small>
                    </span>
                    <span>
                      <strong>Acréscimo:</strong>
                      <small>{maskPrice(payment.AcrescR$, true)}</small>
                    </span>
                    <span>
                      <strong>Acréscimo:</strong>
                      <small>{maskPrice(payment.ValorTotalR$, true)}</small>
                    </span>
                    <span>
                      <strong>Condição:</strong>
                      <small>{payment.CondicaoPagamento}</small>
                    </span>
                    <span>
                      <strong>Observação:</strong>
                      <small>{payment.Observacao}</small>
                    </span>
                  </p>
                ))}
              </div>
            </main>
          ) : null}
        </Content>
      )}
    </Container>
  );
};

export default Pedido;
