import React from 'react';

import { FaTable } from 'react-icons/fa';
import { MdSimCardDownload } from 'react-icons/md';

import { Container, Button } from './styles';

import { ModeProps } from './interface';

const Mode: React.FC<ModeProps> = ({ mode, changeMode }) => {
  return (
    <Container>
      <main>
        <Button
          type="button"
          onClick={() => changeMode('card')}
          active={mode === 'card'}
        >
          <MdSimCardDownload size={22} color="#fff" />
          <span>BLOCO</span>
        </Button>
        <Button
          type="button"
          onClick={() => changeMode('list')}
          active={mode === 'list'}
        >
          <FaTable size={22} color="#fff" />
          <span>LISTA</span>
        </Button>
      </main>
    </Container>
  );
};

export default Mode;
