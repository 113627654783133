import React, { useState, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Button, Stack } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';

import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  Loading,
  Area,
  Checkbox,
} from '~/components';

import { SubMenu, SubMenuBtn } from './styles';

import { FormDataProps } from './interface';

import schema from './schema';

const Empresas: React.FC = () => {
  const navigate = useNavigate();

  const { COLORS } = useTheme();

  const { id } = useParams<{ id: string }>();

  const [inLoad, setInLoad] = useState(false);

  const [initialValues, setInitialValues] = useState<FormDataProps>({
    nome: '',
    idmatriz: '',
    idempresa: '',
  });

  const [ativo, setAtivo] = useState(false);

  const handlerSignUp = (formData: FormDataProps) => {
    return new Promise((resolve, reject) => {
      const handler = async () => {
        try {
          const response = await api[id !== '0' ? 'put' : 'post'](
            `/companies${id !== '0' ? `/${id}` : ''}`,
            {
              idmatriz: formData.idmatriz,
              idempresa: formData.idempresa,
              nome: formData.nome,
              ativo: ativo ? 1 : 0,
            },
          );

          const { data } = response;

          if (!data || (Array.isArray(data) && !data.length)) {
            toast.error(`Credenciais inválidas!`);
            return resolve(true);
          }

          toast.success(`Empresa salva com sucesso!`);

          resolve(true);
        } catch (e) {
          toast.error(`${(e as Error).message}`);
          resolve(true);
        }
      };
      handler();
    });
  };

  useEffect(() => {
    const fetchCompanie = async (id: string) => {
      try {
        setInLoad(true);

        const response = await api.get(`/companies/${id}`);

        const { data } = response;

        if (data && data.length) {
          const cData = data[0];
          if (cData) {
            const { nome, ativo, idmatriz, idempresa } = cData;

            setInitialValues({
              nome: nome,
              idmatriz: idmatriz,
              idempresa: idempresa,
            });
            setAtivo(!!ativo);
          }
        }

        setInLoad(false);
      } catch (e) {
        setInLoad(false);
      }
    };

    if (id && id !== '0') {
      fetchCompanie(id);
    }
  }, [id]);

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Dados da Empresa" />
          <Breadcrumb title="Dados da Empresa" />

          {id !== '0' ? (
            <Area>
              <SubMenu>
                <SubMenuBtn className="active">DADOS DA EMPRESA</SubMenuBtn>
                <SubMenuBtn onClick={() => navigate(`/usuarios/${id}`)}>
                  USUÁRIOS DA EMPRESA
                </SubMenuBtn>
              </SubMenu>
            </Area>
          ) : null}

          {inLoad ? (
            <Loading />
          ) : (
            <Area>
              <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  await handlerSignUp(values);
                  if (id === '0') {
                    resetForm();
                  }
                  setSubmitting(false);
                }}
                validationSchema={schema}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form style={{ width: '100%' }}>
                    <Stack
                      flexDirection={'column'}
                      width={'100%'}
                      mb={'1.5rem'}
                    >
                      <Field
                        component={TextField}
                        label="ID Matriz"
                        name="idmatriz"
                        type="text"
                        variant="outlined"
                      />
                    </Stack>

                    <Stack
                      flexDirection={'column'}
                      width={'100%'}
                      mb={'1.5rem'}
                    >
                      <Field
                        component={TextField}
                        label="ID Empresa"
                        name="idempresa"
                        type="text"
                        variant="outlined"
                      />
                    </Stack>

                    <Stack
                      flexDirection={'column'}
                      width={'100%'}
                      mb={'1.5rem'}
                    >
                      <Field
                        component={TextField}
                        label="Nome"
                        name="nome"
                        type="text"
                        variant="outlined"
                      />
                    </Stack>

                    <Stack
                      flexDirection={'row'}
                      width={'100%'}
                      mb={'2rem'}
                      justifyContent={'flex-start'}
                    >
                      <Checkbox
                        onClick={() => setAtivo(!ativo)}
                        active={ativo}
                        label="Ativo?"
                      />
                    </Stack>

                    <Stack
                      flexDirection={'row'}
                      width={'100%'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        disableElevation
                        onClick={submitForm}
                        variant="contained"
                        size="large"
                        type="submit"
                        style={{
                          fontSize: '1.4rem',
                          fontWeight: '700',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          background: COLORS.BUTTON_BG,
                        }}
                      >
                        SALVAR
                      </Button>
                    </Stack>

                    {isSubmitting && <Loading />}
                  </Form>
                )}
              </Formik>
            </Area>
          )}
        </Container>
      </Gradient>
    </>
  );
};

export default Empresas;
