import styled from 'styled-components';

import { OptProps } from './interface';

export const Container = styled.div`
  width: 340px;
  height: 100%;
  position: fixed;
  padding: 0px;
  left: 0px;
  top: 0px;

  display: flex;
  flex-direction: column;
  z-index: 3000;
  overflow-y: auto;

  .menu-mobile-show {
    display: flex;
  }
  .menu-mobile-hide {
    display: flex;
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    max-height: 100%;

    .menu-mobile-show {
      display: block;
    }
    .menu-mobile-hide {
      display: none;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  float: left;
  width: 100%;
  flex: 1;
  background: ${({ theme }) => theme.COLORS.MENU_BG};
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: inset 1px 1px 5px rgba(255, 255, 255, 0.06);
  flex-direction: column;

  @media (max-width: 1024px) {
    padding: 0;
    position: fixed;
    left: 0px;
    top: 0px;

    align-items: center;
    justify-content: center;

    z-index: 4000;
    background: rgba(0, 0, 0, 0.6);
    height: 100%;
    flex-direction: row;
  }
`;

export const Mobile = styled.div`
  display: none;

  button {
    background: none;
    padding: 0 0 0 1rem;
  }

  h1 {
    display: none;
  }

  @media (max-width: 1024px) {
    display: flex;
    flex-direction: row;
    padding: 0px;
    justify-content: space-between;
    background: ${({ theme }) => theme.COLORS.MENU_BG};
    height: 5rem;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.1);

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
    }

    h1 {
      display: block;
      font-size: 1rem;
      color: ${({ theme }) => theme.COLORS.MENU_TEXT};
      padding: 0 1rem;
    }

    img {
      float: right;
      max-height: 45px;
    }
  }
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  div {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      float: left;
      width: 100%;
    }
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Opts = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  width: 100%;
  float: left;
  margin-top: 0px;
  padding: 2rem 0;

  @media (max-width: 1024px) {
    z-index: 4000;
    width: 80%;
    background: ${({ theme }) => theme.COLORS.MENU_BG};
    height: 100%;
  }
`;
export const OptsGroup = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  float: left;
  justify-content: center;
  margin-top: 0px;
  flex: 1;

  @media (max-width: 1024px) {
    justify-content: flex-start;
  }
`;

export const Opt = styled.div<OptProps>`
  display: flex;
  align-items: center;
  flex-direction: row;

  background: ${({ theme, active }) =>
    active ? theme.COLORS.MENU_LINK_BG_HOVER : theme.COLORS.MENU_LINK_BG};

  width: 100%;
  margin: 0px;

  float: left;

  padding: 0.3rem 0px;
  transition: all 0.5s ease;
  cursor: pointer;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  i {
    float: left;
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    color: ${({ theme, active }) =>
      active ? theme.COLORS.MENU_LINK_TEXT_HOVER : theme.COLORS.MENU_LINK_TEXT};
    font-size: 1rem;
    margin-left: 0.6rem;
  }

  @media (max-width: 1024px) {
    padding: 0.5rem;

    i {
      width: 2rem;
      height: 2rem;
    }
    span {
      font-size: 1rem;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  padding-bottom: 2rem;

  img {
    max-height: 4.5rem;
  }

  span {
    color: ${({ theme }) => theme.COLORS.MENU_VERSION};
    font-size: 0.8rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.COLORS.MENU_TEXT};
    margin: 1rem 0 0 0;
  }

  @media (max-width: 1024px) {
    padding-bottom: 1.5rem;

    img {
      height: 40px;
    }

    h1 {
      font-size: 0.8rem;
    }
  }
`;

export const Close = styled.div`
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    position: fixed;
    z-index: 4000;
    right: 0px;
    top: 0px;
    padding: 1rem 1rem 0 0;
  }
`;

export const Business = styled.button`
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;

  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;

    span {
      color: rgba(255, 255, 255, 0.6);
      font-size: 0.8rem;
    }

    strong {
      color: #fff;
      font-size: 1rem;
      margin: 0 0 0.2rem 0;
    }

    small {
      color: rgba(255, 255, 255, 0.8);
      background: rgba(255, 255, 255, 0.1);
      padding: 0.4rem;
      border-radius: 0.4rem;
    }
  }

  @media (max-width: 1024px) {
    div {
      span {
        font-size: 0.6rem;
      }

      strong {
        font-size: 0.8rem;
      }

      small {
        font-size: 0.5rem;
      }
    }
  }
`;
