import React from 'react';

import { Content } from './styles';

import { ContainerProps } from './interface';

const Container: React.FC<ContainerProps> = ({ children }) => {
  return <Content>{children}</Content>;
};

export default Container;
