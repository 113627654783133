import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20000;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  width: 700px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

export const Message = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.COLORS.SESSION_TITLE};

  font-size: 25px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 22px;
  }
`;

export const SubMessage = styled.div`
  color: ${({ theme }) => theme.COLORS.SESSION_SUBTITLE};
  font-size: 14px;
  margin: 5px 0 40px 0;
  padding: 0 50px;
  text-align: center;

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;

export const Back = styled.button`
  background: ${({ theme }) => theme.COLORS.SESSION_BACK_BG};
  color: ${({ theme }) => theme.COLORS.SESSION_BACK_TEXT};

  padding: 2rem;
  margin-top: 2rem;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 700;
  outline: none;
  border-radius: 0.6rem;
  transition: all 0.5s ease;
  text-align: center;
  border: none;

  &:hover {
    background: ${({ theme }) => theme.COLORS.SESSION_BACK_BG_HOVER};
  }

  @media (max-width: 1024px) {
    font-size: 1.2rem;
  }
`;
