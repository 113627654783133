import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  float: left;
  flex-direction: row;
  display: flex;
  background: none;
  justify-content: flex-start;

  span {
    font-size: 20px;
    color: #fff;
    margin-left: 15px;
  }
`;
