import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

import api from '~/services/api';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  Totals,
  Counter,
  List,
  Card,
  Loading,
  Pagination,
  Mode,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
} from '~/components';

import Filtros from './Filtros';

import { FormDataProps } from './Filtros/interface';

import { TotalProps } from '~/components/Totals/interface';

import qs from 'qs';
import { maskPrice } from '~/helpers/money';
import Cashbox from '~/@types/Cashbox';

const Caixa: React.FC = () => {
  const navigate = useNavigate();

  const [inLoad, setInLoad] = useState(false);

  const [listType, setListType] = useState('card');
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [totalsVisible, setTotalsVisible] = useState(false);

  const [filtersTitle, setFiltersTitle] = useState<string | null>(null);
  const [filtersSubtitle, setFiltersSubtitle] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);

  const [filters, setFilters] = useState<FormDataProps | null>(null);
  const [items, setItems] = useState<Cashbox[]>([]);
  const [totals, setTotals] = useState<TotalProps[]>([]);

  const onChangePage = (cPage: number) => {
    setItems([]);
    fetchResults(filters, cPage);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const closeFilters = () => {
    setFiltersVisible(false);
  };

  const toggleTotals = () => {
    setTotalsVisible(!totalsVisible);
  };

  const calcTotalFilters = (filtros: FormDataProps, totalReg: number) => {
    let inFilters: string[] = [];
    if (filtros.funcionario) {
      inFilters.push(`funcionário`);
    }
    if (filtros.tipo) {
      inFilters.push(`tipo`);
    }
    if (filtros.dtinicial) {
      inFilters.push(`data inicial`);
    }
    if (filtros.dtfinal) {
      inFilters.push(`data final`);
    }

    let message = null;
    if (inFilters.length) {
      const last = inFilters.pop();
      if (inFilters.length) {
        message = `Filtrando por: ${inFilters.join(', ')} e ${last}`;
      } else {
        message = `Filtrando por: ${last}`;
      }
    }

    setFiltersTitle(message);
    setFiltersSubtitle(`${totalReg} registros encontrados.`);
  };

  const onApplyFilters = async (filtros: FormDataProps) => {
    setFilters(filtros);
    fetchResults(filtros, 1);
  };

  const fetchResults = async (filtros: FormDataProps | null, cPage: number) => {
    try {
      setCurrentPage(cPage);

      if (cPage === 1) {
        setTotalPages(0);
        setTotalRegisters(0);
        setTotals([]);
        setItems([]);
      }

      setInLoad(true);

      let params = null;
      if (filtros) {
        params = qs.stringify({
          dateFinal: filtros.dtfinal,
          dateInitial: filtros.dtinicial,
          funcionario: filtros.funcionario,
          type: filtros.tipo,
          page: cPage,
          perPage: 20,
        });
      }

      const response = await api.get(
        `/finantial/cashbox${params ? `?${params}` : ''}`,
      );

      const { data } = response;

      const { data: items, total, pages, totalizadores } = data;
      if (items && items.length) {
        setItems(items);
        setTotalRegisters(total);
        setTotalPages(pages);
        setTotals([
          ...Object.getOwnPropertyNames(totalizadores).map(prop => ({
            label: `${prop}`,
            value: `${totalizadores[prop]}`,
          })),
        ]);
      }

      if (filtros) {
        calcTotalFilters(filtros, total);
      }

      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onReset = () => {
    setFiltersVisible(true);
  };

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Caixa" />
          <Breadcrumb
            title="Caixa"
            isSearch
            onSearch={(searchStr: string) => alert(searchStr)}
            onToggleFilters={toggleFilters}
            filterStatus={filtersVisible}
          />
          <Filtros
            visible={filtersVisible}
            onApplyFilters={onApplyFilters}
            closeFilters={closeFilters}
          />

          {filtersTitle && filtersSubtitle ? (
            <Counter
              title={filtersTitle}
              subtitle={filtersSubtitle}
              onReset={onReset}
            />
          ) : null}

          {inLoad && <Loading />}

          {items.length ? (
            <Mode mode={listType} changeMode={mode => setListType(mode)} />
          ) : null}

          {listType === 'card' ? (
            <List>
              {items.map(item => (
                <Card
                  onClick={() => navigate(`/caixa/${item.cdControleCaixa}`)}
                  key={item.cdControleCaixa}
                  lines={[
                    [
                      {
                        label: 'Número:',
                        value: `${item.cdControleCaixa}`,
                      },
                      {
                        label: 'Data:',
                        value: `${item.dtDataAbertura}`,
                      },
                      {
                        label: 'Usuário:',
                        value: `${item.nome}`,
                      },
                    ],
                    [
                      {
                        label: 'Cartão R$:',
                        value: `${maskPrice(item.vlCartao)}`,
                      },
                      {
                        label: 'Dinheiro R$:',
                        value: `${maskPrice(item.vlDinheiro)}`,
                      },
                    ],
                    [
                      {
                        label: 'Cheque R$:',
                        value: `${maskPrice(item.vlCheque)}`,
                      },
                      {
                        label: 'Prazo R$:',
                        value: `${maskPrice(item.vlPrazo)}`,
                      },
                    ],
                    [
                      {
                        label: 'Pix R$:',
                        value: `0,00`,
                      },
                      {
                        label: 'Saldo Inicial R$:',
                        value: `${maskPrice(item.vlSaldoInicial)}`,
                      },
                    ],
                  ]}
                />
              ))}
            </List>
          ) : (
            <>
              {items.length ? (
                <Grid>
                  <GridLine stick>
                    <GridHeadItem title="Número" width={100} />
                    <GridHeadItem title="Data" />
                    <GridHeadItem title="Usuário" />
                    <GridHeadItem title="Cartão R$" />
                    <GridHeadItem title="Dinheiro R$" />
                    <GridHeadItem title="Cheque R$" />
                    <GridHeadItem title="Prazo R$" />
                    <GridHeadItem title="Pix R$" />
                    <GridHeadItem title="Saldo Inicial R$" />
                  </GridLine>

                  {items.map(item => (
                    <GridLine
                      key={item.cdControleCaixa}
                      onClick={() =>
                        navigate(`/caixa/${item.cdControleCaixa}`)
                      }
                    >
                      <GridItem width={100}>
                        <small>{item.cdControleCaixa}</small>
                      </GridItem>
                      <GridItem>{item.dtDataAbertura}</GridItem>
                      <GridItem>
                        <strong>{item.nome}</strong>
                      </GridItem>
                      <GridItem>{maskPrice(item.vlCartao, true)}</GridItem>
                      <GridItem>{maskPrice(item.vlDinheiro, true)}</GridItem>
                      <GridItem>{maskPrice(item.vlCheque, true)}</GridItem>
                      <GridItem>{maskPrice(item.vlPrazo, true)}</GridItem>
                      <GridItem>{`0,00`}</GridItem>
                      <GridItem>{maskPrice(item.vlSaldoInicial, true)}</GridItem>
                    </GridLine>
                  ))}
                </Grid>
              ) : null}
            </>
          )}

          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRegisters={totalRegisters}
              onChangePage={onChangePage}
            />
          ) : null}

          <Totals
            visible={totalsVisible}
            onToggle={toggleTotals}
            items={totals}
          />
        </Container>
      </Gradient>
    </>
  );
};

export default Caixa;
