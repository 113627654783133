import React from 'react';

import { Container, Background, Content, Circles } from './styles';

import { GradientProps } from './interface';

const Gradient: React.FC<GradientProps> = ({ children }) => {
  return (
    <Container>
      <Background />
      <Content>{children}</Content>
      <Circles>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </Circles>
    </Container>
  );
};

export default Gradient;
