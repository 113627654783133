import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '~/services/api';

import User from '~/@types/User';

import { AuthContextData, AuthState } from './interface';

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [data, setData] = useState<AuthState>(() => {
    const idempresa = localStorage.getItem('@SisautDash:idempresa');
    const token = localStorage.getItem('@SisautDash:token');
    const user = localStorage.getItem('@SisautDash:user');

    if (token && user && idempresa) {
      const userData: User = JSON.parse(user);
      if (userData) {
        api.defaults.headers.common.authorization = `Bearer ${token}`;
        api.defaults.headers.common.idempresa = idempresa;
      }
      return { token, user: userData, idempresa };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async (user: User, token: string) => {
    localStorage.setItem('@SisautDash:token', token);
    localStorage.setItem('@SisautDash:idempresa', user.idempresa);
    localStorage.setItem('@SisautDash:user', JSON.stringify(user));

    api.defaults.headers.common.authorization = `Bearer ${token}`;
    api.defaults.headers.common.idempresa = user.idempresa;

    setData({ token, user: user, idempresa: user.idempresa });
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@SisautDash:token');
    localStorage.removeItem('@SisautDash:user');
    localStorage.removeItem('@SisautDash:idempresa');
    setData({} as AuthState);
  }, []);

  const updateUser = useCallback(
    (user: User) => {
      setData({
        token: data.token,
        user,
        idempresa: data.idempresa,
      });
      localStorage.setItem('@SisautDash:user', JSON.stringify(user));
    },
    [setData, data.token, data.idempresa],
  );

  const getAuthUser = () => {
    let userData;
    const user = localStorage.getItem('@SisautDash:user');
    if (user) {
      userData = JSON.parse(user);
    }
    return userData;
  };

  const setBusiness = (idempresa: string) => {
    api.defaults.headers.common.idempresa = idempresa;
    localStorage.setItem('@SisautDash:idempresa', idempresa);
    setData({
      token: data.token,
      user: data.user,
      idempresa: idempresa,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        idempresa: data.idempresa,
        signIn,
        signOut,
        updateUser,
        getAuthUser,
        setBusiness,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
