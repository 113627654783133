import styled from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.td<ContainerProps>`
  width: ${props => (props.width ? `${props.width}px !important` : 'auto')};
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  background: ${({ theme }) => theme.COLORS.GRID_HEAD_ITEM_BG};
  padding: 1rem;
  font-size: 1rem;

  &:first-child {
    border: none;
  }

  strong {
    font-weight: 700;
  }

  span {
    margin: 0px;
    padding: 0px;
    flex: 1;
    cursor: pointer;
    text-decoration: ${props => (props.active ? 'underline' : 'normal')};
    color: ${({ theme }) => theme.COLORS.GRID_HEAD_ITEM_TEXT};
  }

  span:hover {
    text-decoration: underline;
  }

  i {
    margin: 0px;
    padding: 0px;
  }
`;
