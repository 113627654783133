import React from 'react';

import { SlGraph } from 'react-icons/sl';

import { Anchor, Resume } from './styles';

import { TotalsProps } from './interface';

const Totals: React.FC<TotalsProps> = ({ items, visible, onToggle }) => {
  if (!visible) {
    return (
      <Anchor onClick={onToggle}>
        <SlGraph size={32} color="#fff" />
      </Anchor>
    );
  }

  return (
    <Resume>
      <main>
        <i>
          <SlGraph size={32} color="#fff" />
        </i>
        <h1>TOTALIZADORES</h1>
      </main>
      <div>
        {items.map(item => (
          <span>
            <strong>{item.label}</strong>
            <small>{item.value}</small>
          </span>
        ))}
      </div>
      <button type="button" onClick={onToggle}>
        FECHAR
      </button>
    </Resume>
  );
};

export default Totals;
