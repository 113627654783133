import styled from 'styled-components';

import { ButtonProps } from './interface';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 1rem;
  float: left;
  width: 100%;
  margin: 2rem 0;

  main {
    display: flex;
    flex-direction: row;
    float: left;
    width: 100%;
    background: ${({ theme }) => theme.COLORS.MODE_BG};
    border-radius: 0.4rem;
    overflow: hidden;
    max-width: 30rem;
  }

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

export const Button = styled.button<ButtonProps>`
  float: left;
  flex: 1;
  background: ${({ theme, active }) =>
    active ? theme.COLORS.MODE_BUTTON_BG_ACTIVE : theme.COLORS.MODE_BUTTON_BG};
  color: ${({ theme }) => theme.COLORS.MODE_BUTTON_TEXT};
  padding: 1rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  span {
    font-size: 1rem;
    padding-left: 0.5rem;
  }
  @media (max-width: 1024px) {
  }
`;
