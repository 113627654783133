import styled from 'styled-components';

export const Content = styled.div`
  background: ${({ theme }) => theme.COLORS.DASH_CARD_BG};
  float: left;
  width: 100%;
  float: left;

  display: flex;
  flex-direction: column;

  padding: 2rem;

  main {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    /* margin-bottom: 3rem; */
  }

  div {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-top: 5px solid rgba(255, 255, 255, 0.2);
    padding: 2rem 0 0 0;
  }

  p {
    float: left;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 0.5rem;
    margin-top: 1rem;
  }

  h1 {
    color: #fff;
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  span {
    float: left;
    width: 25%;
    display: flex;
    flex-direction: row;
    /* margin-bottom: 2rem; */
    align-items: flex-start;
    padding: 0.5rem;

    strong {
      color: ${({ theme }) => theme.COLORS.SESSION_SUBTITLE};
      font-size: 1rem;
      /* border-radius: 0.5rem;
      background: rgba(255, 255, 255, 0.2); */
      /* padding: 0.6rem;
      margin-bottom: 0.6rem; */
    }

    small {
      color: #fff;
      margin-left: 0.5rem;
      font-size: 1rem;
    }
  }

  @media (max-width: 1024px) {
    padding: 2.5rem 0 0 0;
    overflow-x: hidden;
  }
`;
