import React, { useState } from 'react';

import api from '~/services/api';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  Totals,
  Counter,
  List,
  Card,
  Loading,
  Pagination,
  Mode,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
} from '~/components';

import Filtros from './Filtros';

import { FormDataProps } from './Filtros/interface';

import { TotalProps } from '~/components/Totals/interface';
import Product from '~/@types/Product';

import qs from 'qs';
import { maskPrice } from '~/helpers/money';

const Produtos: React.FC = () => {
  const [inLoad, setInLoad] = useState(false);

  const [listType, setListType] = useState('card');
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [totalsVisible, setTotalsVisible] = useState(false);

  const [filtersTitle, setFiltersTitle] = useState<string | null>(null);
  const [filtersSubtitle, setFiltersSubtitle] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);

  const [filters, setFilters] = useState<FormDataProps | null>(null);
  const [items, setItems] = useState<Product[]>([]);
  const [totals, setTotals] = useState<TotalProps[]>([]);

  const onChangePage = (cPage: number) => {
    setItems([]);
    fetchResults(filters, cPage);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const closeFilters = () => {
    setFiltersVisible(false);
  };

  const toggleTotals = () => {
    setTotalsVisible(!totalsVisible);
  };

  const calcTotalFilters = (filtros: FormDataProps, totalReg: number) => {
    let inFilters: string[] = [];
    if (filtros.estoque) {
      inFilters.push(`estoque`);
    }
    if (filtros.deposito) {
      inFilters.push(`depósito`);
    }
    if (filtros.produto) {
      inFilters.push(`produto`);
    }
    if (filtros.grupo) {
      inFilters.push(`grupo`);
    }
    if (filtros.marca) {
      inFilters.push(`marca`);
    }
    if (filtros.secao) {
      inFilters.push(`sessão`);
    }
    if (filtros.tipo) {
      inFilters.push(`tipo`);
    }

    let message = null;
    if (inFilters.length) {
      const last = inFilters.pop();
      if (inFilters.length) {
        message = `Filtrando por: ${inFilters.join(', ')} e ${last}`;
      } else {
        message = `Filtrando por: ${last}`;
      }
    }

    setFiltersTitle(message);
    setFiltersSubtitle(`${totalReg} registros encontrados.`);
  };

  const onApplyFilters = async (filtros: FormDataProps) => {
    setFilters(filtros);
    fetchResults(filtros, 1);
  };

  const fetchResults = async (filtros: FormDataProps | null, cPage: number) => {
    try {
      setCurrentPage(cPage);

      if (cPage === 1) {
        setTotalPages(0);
        setTotalRegisters(0);
        setTotals([]);
        setItems([]);
      }

      setInLoad(true);

      let params = null;
      if (filtros) {
        params = qs.stringify(
          {
            estoque: filtros.estoque,
            deposito: filtros.deposito,
            product: filtros.produto,
            group: filtros.grupo,
            mark: filtros.marca,
            section: filtros.secao,
            tipo: filtros.tipo,
            page: cPage,
            perPage: 20,
          },
          { skipNulls: true },
        );
      }

      const response = await api.get(
        `/products/stock${params ? `?${params}` : ''}`,
      );

      const { data } = response;

      const { data: items, total, pages, totalizadores } = data;
      if (items && items.length) {
        setItems(items);
        setTotalRegisters(total);
        setTotalPages(pages);
        setTotals([
          ...Object.getOwnPropertyNames(totalizadores).map(prop => ({
            label: `${prop}`,
            value: `${maskPrice(totalizadores[prop])}`,
          })),
        ]);
      }

      if (filtros) {
        calcTotalFilters(filtros, total);
      }

      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onReset = () => {
    setFiltersVisible(true);
  };

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Produtos" />
          <Breadcrumb
            title="Produtos"
            isSearch
            onSearch={(searchStr: string) => null}
            onToggleFilters={toggleFilters}
            filterStatus={filtersVisible}
          />
          <Filtros
            visible={filtersVisible}
            onApplyFilters={onApplyFilters}
            closeFilters={closeFilters}
          />

          {filtersTitle && filtersSubtitle ? (
            <Counter
              title={filtersTitle}
              subtitle={filtersSubtitle}
              onReset={onReset}
            />
          ) : null}

          {inLoad && <Loading />}

          {items.length ? (
            <Mode mode={listType} changeMode={mode => setListType(mode)} />
          ) : null}

          {listType === 'card' ? (
            <List>
              {items.map(item => (
                <Card
                  key={item.codigo}
                  lines={[
                    [
                      {
                        label: 'Produto:',
                        value: `${item.nome}`,
                      },
                    ],
                    [
                      {
                        label: 'Fornecedor:',
                        value: `${item.dsFornecedor}`,
                      },
                      {
                        label: 'Unidade:',
                        value: `${item.unidade}`,
                      },
                    ],
                    [
                      {
                        label: 'Grupo:',
                        value: `${item.dsGrupo}`,
                      },
                      {
                        label: 'Marca:',
                        value: `${item.dsMarca}`,
                      },
                    ],
                    [
                      {
                        label: 'Custo R$:',
                        value: `${maskPrice(Number(item.custo))}`,
                      },
                      {
                        label: 'Estoque:',
                        value: `${maskPrice(Number(item.Estoque))}`,
                      },
                    ],
                    [
                      {
                        label: 'Preço RS:',
                        value: `${maskPrice(Number(item.venda))}`,
                      },
                      {
                        label: 'Depósito:',
                        value: `${maskPrice(Number(item.Deposito))}`,
                      },
                    ],
                  ]}
                />
              ))}
            </List>
          ) : (
            <>
              {items.length ? (
                <Grid>
                  <GridLine stick>
                    <GridHeadItem title="Sequência" width={100} />
                    <GridHeadItem title="Produto" />
                    <GridHeadItem title="Unidade" />
                    <GridHeadItem title="Fornecedor" />
                    <GridHeadItem title="Grupo" />
                    <GridHeadItem title="Marca" />
                    <GridHeadItem title="Custo" />
                    <GridHeadItem title="Preço Venda" />
                    <GridHeadItem title="Estoque" />
                    <GridHeadItem title="Depósito" />
                  </GridLine>

                  {items.map(item => (
                    <GridLine key={item.codigo}>
                      <GridItem width={100}>
                        <small>{item.codigo}</small>
                      </GridItem>
                      <GridItem>
                        <strong>{item.nome}</strong>
                      </GridItem>
                      <GridItem>{item.unidade}</GridItem>
                      <GridItem>{item.dsFornecedor}</GridItem>
                      <GridItem>{item.dsGrupo}</GridItem>
                      <GridItem>{item.dsMarca}</GridItem>
                      <GridItem>{maskPrice(item.custo, true)}</GridItem>
                      <GridItem>{maskPrice(item.venda, true)}</GridItem>
                      <GridItem>{maskPrice(item.Estoque)}</GridItem>
                      <GridItem>{maskPrice(item.Deposito)}</GridItem>
                    </GridLine>
                  ))}
                </Grid>
              ) : null}
            </>
          )}

          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRegisters={totalRegisters}
              onChangePage={onChangePage}
            />
          ) : null}

          <Totals
            visible={totalsVisible}
            onToggle={toggleTotals}
            items={totals}
          />
        </Container>
      </Gradient>
    </>
  );
};

export default Produtos;
