import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { useAuth } from '~/hooks/auth';

import {
  Gradient,
  Container,
  Menu,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
  Loading,
  Breadcrumb,
} from '~/components';

import { Content, Line, Card, Space } from './styles';

import Graph1 from './Graph1';
import Graph2 from './Graph2';

import { ComparativoProps } from './interface';
import { maskPrice } from '~/helpers/money';

const Comparativo: React.FC = () => {
  const { user } = useAuth();

  const [inLoad, setInLoad] = useState(true);

  const [ticketsMedium, setTicketsMedium] = useState<ComparativoProps[]>([]);
  const [gains, setGains] = useState<ComparativoProps[]>([]);
  const [billings, setBillings] = useState<ComparativoProps[]>([]);

  const fetchMonths = (tickets: ComparativoProps[]) => {
    return tickets.reduce((acc: string[], item: ComparativoProps) => {
      if (!acc.includes(item.mes)) {
        acc.push(item.mes);
      }
      return acc;
    }, []);
  };

  const fetchMonthLabel = (pMonth: string) => {
    const refs = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    const dataInMonth = pMonth.split('-');
    const monthRef = Number(dataInMonth[1]);
    return `${refs[monthRef - 1].toUpperCase()}`;
  };

  const fetchDataForGraph = (pItems: ComparativoProps[]) => {
    const output: Array<{
      [key: string]: any;
    }> = [];
    const monthsInData = fetchMonths(pItems);
    monthsInData.forEach(month => {
      let outputItem: {
        [key: string]: any;
      } = {
        mes: fetchMonthLabel(month),
      };
      pItems
        .filter(cItem => cItem.mes === month)
        .forEach(cItem => {
          outputItem[cItem.nome] = cItem.value;
        });

      output.push(outputItem);
    });

    return output;
  };

  const fetchLinesForGraph = (pItems: ComparativoProps[]) => {
    const COLORS = ['#ffa366', '#147a1c', '#0ccef0'];
    const output = pItems.reduce(
      (
        acc: Array<{ key: string; color: string }>,
        item: ComparativoProps,
        index,
      ) => {
        if (!acc.filter(cItem => cItem.key === item.nome).length) {
          acc.push({
            color: `${COLORS[index] || '#ccc'}`,
            key: item.nome,
          });
        }
        return acc;
      },
      [],
    );
    return output;
  };

  useEffect(() => {
    const fetchIndicators = async () => {
      try {
        setInLoad(true);

        const response = await api.get(`/companies/chart`, {
          headers: {
            idempresa: user.idempresa, //MATRIZ (IMPORTANTE)
          },
        });

        const { data } = response;

        if (data) {
          const { ticketMedioData, LucroData, mesData } = data;
          setTicketsMedium(ticketMedioData);
          setGains(LucroData);
          setBillings(mesData);
        }

        setInLoad(false);
      } catch (e) {
        setInLoad(false);
      }
    };

    fetchIndicators();
  }, [user.idempresa]);

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Comparativo" />
          <Breadcrumb title="Comparativo" />

          {inLoad ? (
            <Loading />
          ) : (
            <Content>
              <Line>
                <Card>
                  <header>
                    <h2>Faturamento (R$)</h2>
                  </header>

                  <Graph1
                    data={fetchDataForGraph(billings)}
                    lines={fetchLinesForGraph(billings)}
                  />

                  <Grid>
                    {/* <GridLine>
                      <GridHeadItem title="Empresa" />
                      <GridHeadItem title="Total" />
                    </GridLine> */}

                    {fetchMonths(billings).map(month => (
                      <>
                        <GridLine>
                          <GridItem colSpan={2} style={{ textAlign: 'center' }}>
                            <strong>
                              <ins>{fetchMonthLabel(month)}</ins>
                            </strong>
                          </GridItem>
                        </GridLine>
                        {billings
                          .filter(billing => billing.mes === month)
                          .map(billing => (
                            <GridLine>
                              <GridItem>
                                <small>{billing.nome}</small>
                              </GridItem>
                              <GridItem>{maskPrice(billing.value, true)}</GridItem>
                            </GridLine>
                          ))}
                      </>
                    ))}
                  </Grid>
                </Card>

                <Space />

                <Card>
                  <header>
                    <h2>Ticket Médio (R$)</h2>
                  </header>

                  <Graph1
                    data={fetchDataForGraph(ticketsMedium)}
                    lines={fetchLinesForGraph(ticketsMedium)}
                  />

                  <Grid>
                    {/* <GridLine>
                      <GridHeadItem title="Empresa" />
                      <GridHeadItem title="Total" />
                    </GridLine> */}

                    {fetchMonths(ticketsMedium).map(month => (
                      <>
                        <GridLine>
                          <GridItem colSpan={2} style={{ textAlign: 'center' }}>
                            <strong>
                              <ins>{fetchMonthLabel(month)}</ins>
                            </strong>
                          </GridItem>
                        </GridLine>
                        {ticketsMedium
                          .filter(ticket => ticket.mes === month)
                          .map(ticket => (
                            <GridLine>
                              <GridItem>
                                <small>{ticket.nome}</small>
                              </GridItem>
                              <GridItem>{maskPrice(ticket.value, true)}</GridItem>
                            </GridLine>
                          ))}
                      </>
                    ))}
                  </Grid>
                </Card>

                <Space />

                <Card>
                  <header>
                    <h2>Lucro (%)</h2>
                  </header>

                  <Graph2
                    data={fetchDataForGraph(gains)}
                    lines={fetchLinesForGraph(gains)}
                  />

                  <Grid>
                    {/* <GridLine>
                      <GridHeadItem title="Empresa" />
                      <GridHeadItem title="Total" />
                    </GridLine> */}

                    {fetchMonths(gains).map(month => (
                      <>
                        <GridLine>
                          <GridItem colSpan={2} style={{ textAlign: 'center' }}>
                            <strong>
                              <ins>{fetchMonthLabel(month)}</ins>
                            </strong>
                          </GridItem>
                        </GridLine>
                        {gains
                          .filter(gain => gain.mes === month)
                          .map(gain => (
                            <GridLine>
                              <GridItem>
                                <small>{gain.nome}</small>
                              </GridItem>
                              <GridItem>{maskPrice(gain.value, false, '', '%')}</GridItem>
                            </GridLine>
                          ))}
                      </>
                    ))}
                  </Grid>
                </Card>
              </Line>
            </Content>
          )}
        </Container>
      </Gradient>
    </>
  );
};

export default Comparativo;
