import React from 'react';

import { Scroll, Container } from './styles';

import { GridProps } from './interface';

const GridContainer: React.FC<GridProps> = ({ light, children }) => {
  return (
    <Scroll>
      <Container light={light}>{children}</Container>
    </Scroll>
  );
};

export default GridContainer;
