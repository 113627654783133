import React, { createContext, useCallback, useState, useContext } from 'react';

import { AdminContextData } from './interface';

const AdminContext = createContext<AdminContextData>({} as AdminContextData);

export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [token, setToken] = useState<string | null | undefined>(() => {
    const token = localStorage.getItem('@SisautDash:admin_token');
    if (token) {
      return token;
    }
    return null;
  });

  const signIn = useCallback(async (token: string) => {
    localStorage.setItem('@SisautDash:admin_token', token);
    setToken(token);
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@SisautDash:admin_token');
    setToken(null);
  }, []);

  return (
    <AdminContext.Provider
      value={{
        token,
        signIn,
        signOut,
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};

export function useAdmin(): AdminContextData {
  const context = useContext(AdminContext);

  if (!context) {
    throw new Error('useAdmin must be used within an AdminProvider');
  }

  return context;
}
