const light = {
  COLORS: {
    TEXT: '#FFF',
    BUTTON_BG: '#314c7b',

    SESSION_TITLE: '#333',
    SESSION_SUBTITLE: '#666',
    SESSION_BACK_BG: '#314c7b',
    SESSION_BACK_BG_HOVER: '#333',
    SESSION_BACK_TEXT: '#FFF',

    GRADIENT_COLOR_1: '#1e2633',
    GRADIENT_COLOR_2: '#2c2c2c',
    GRADIENT_CIRCLE: 'rgba(255,255,255,0.1)',

    BAR_CONTAINER_BG: 'rgba(255,255,255,0.1)',
    BAR_PROGRESS_BG: '#598d4f',

    MENU_BG: '#273041',
    MENU_TEXT: '#fff',
    MENU_LINK_BG: 'rgba(0,0,0,0)',
    MENU_LINK_BG_HOVER: '#202938',
    MENU_LINK_TEXT: '#fff',
    MENU_LINK_TEXT_HOVER: '#FFF',
    MENU_IC: '#fff',
    MENU_VERSION: '#666',

    HEADER_BG: '#fff',
    HEADER_TEXT: '#333',
    HEADER_IC: '#333',
    HEADER_SEARCH_COLOR: '#333',
    HEADER_BUTTON_BG: '#EEE',
    HEADER_BUTTON_TEXT: '#333',
    HEADER_BUTTON_BG_HOVER: 'rgba(0,0,0,0.1)',
    HEADER_BUTTON_TEXT_HOVER: '#333',

    BREADCRUMB_BG: '#273041',

    FILTERS_BG: '#273041',

    FINDER_BG: '#273041',
    FINDER_TEXT: '#FFF',

    CARD_BG: '#273041',
    CARD_BG_ACTIVE: '#314c7b',
    CARD_LABEL: '#5b89d4',
    CARD_VALUE: '#fff',

    COUNTER_BG: '#5f84cb',
    COUNTER_TEXT: '#FFF',

    TOTALS_ANCHOR_BG: '#608b5e',
    TOTALS_RESUME_BG: '#5f84cb',
    TOTALS_TEXT: '#FFF',
    TOTALS_CLOSE_BG: '#314c7b',

    PAGINATION_BG: '#5f84cb',

    GRID_BG: '#273041',
    GRID_HEAD_ITEM_BG: '#314c7b',
    GRID_HEAD_ITEM_TEXT: '#fff',
    GRID_ITEM_TEXT: '#fff',
    GRID_ITEM_TEXT_BOLD: '#fff',
    GRID_MENU_IC: '#fff',

    MODE_BG: '#5f84cb',
    MODE_BUTTON_BG: '#314c7b',
    MODE_BUTTON_BG_ACTIVE: '#608b5e',
    MODE_BUTTON_TEXT: '#FFF',

    DASH_CARD_BG: '#273041',
    DASH_CARD_TAG: '#4878c2',

    PLUS_BG: '#608b5e',
    PLUS_BG_HOVER: '#608b3e',

    AREA_BG: '#273041',
    LABEL_ORANGE: '#ED7D31',
  },
};

export default light;
