import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { Gradient, Container, Menu, Loading, Breadcrumb } from '~/components';

import { Content } from './styles';

import OrderPayment from '~/@types/OrderPayment';
import OrderItem from '~/@types/OrderItem';
import { maskPrice } from '~/helpers/money';

const Pedido: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [inLoad, setInLoad] = useState(true);

  const [items, setItems] = useState<OrderItem[]>([]);
  const [payments, setPayments] = useState<OrderPayment[]>([]);

  useEffect(() => {
    const fetchOrder = async (pId: string) => {
      try {
        setInLoad(true);

        const response = await api.get(`sales/search/${pId}`);
        const { data } = response;
        if (data) {
          const { itensSQL, formasPagtoSQL } = data;
          setItems(itensSQL);
          setPayments(formasPagtoSQL);
        }
        setInLoad(false);
      } catch (e) {
        setInLoad(false);
      }
    };

    if (id) {
      fetchOrder(id);
    }
  }, [id]);

  return (
    <>
      <Gradient>
        <Container>
          <Menu title={`Itens do Pedido: ${id}`} />
          <Breadcrumb title={`Itens do Pedido: ${id}`} />
          {inLoad && <Loading />}

          <Content>
            <main>
              <h1>Detalhes do Pedido</h1>
              <div>
                <p>
                  <span>
                    <strong>Código:</strong>
                    <small>{id}</small>
                  </span>
                </p>
              </div>
            </main>

            {items.length ? (
              <main>
                <h1>Itens do Pedido</h1>
                <div>
                  {items.map(item => (
                    <p>
                      <span>
                        <strong>Código:</strong>
                        <small>{item.codpro}</small>
                      </span>
                      <span>
                        <strong>Descrição:</strong>
                        <small>{item.descricao}</small>
                      </span>
                      <span>
                        <strong>Quantidade:</strong>
                        <small>{maskPrice(item.qtd)}</small>
                      </span>
                      <span>
                        <strong>Valor Unitário:</strong>
                        <small>{maskPrice(item.vlUnitario, true)}</small>
                      </span>
                    </p>
                  ))}
                </div>
              </main>
            ) : null}

            {payments.length ? (
              <main>
                <h1>Formas de Pagamento</h1>
                <div>
                  {payments.map(payment => (
                    <p>
                      <span>
                        <strong>Código:</strong>
                        <small>{payment.codigo}</small>
                      </span>
                      <span>
                        <strong>Descrição:</strong>
                        <small>{payment.Descricao}</small>
                      </span>
                      <span>
                        <strong>Subtotal:</strong>
                        <small>{maskPrice(payment.SubTotalR$, true)}</small>
                      </span>
                      <span>
                        <strong>Acréscimo:</strong>
                        <small>{maskPrice(payment.AcrescR$, true)}</small>
                      </span>
                      <span>
                        <strong>Acréscimo:</strong>
                        <small>{maskPrice(payment.ValorTotalR$, true)}</small>
                      </span>
                      <span>
                        <strong>Condição:</strong>
                        <small>{payment.CondicaoPagamento}</small>
                      </span>
                      <span>
                        <strong>Observação:</strong>
                        <small>{payment.Observacao}</small>
                      </span>
                    </p>
                  ))}
                </div>
              </main>
            ) : null}
          </Content>
        </Container>
      </Gradient>
    </>
  );
};

export default Pedido;
