import styled from 'styled-components';

export const Content = styled.main`
  padding: 2rem 2rem 0 2rem;
  float: left;
  width: 100%;

  @media (max-width: 1024px) {
    padding: 0rem;
    background: ${({ theme }) => theme.COLORS.DASH_CARD_BG};
  }
`;

export const Info = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 5px solid rgba(255, 255, 255, 0.08);
  padding: 0 0 2rem 0;
  margin-bottom: 3rem;

  h1 {
    font-size: 2rem;
    color: #fff;
  }

  h2 {
    font-size: 1.4rem;
    color: #fff;
    font-weight: normal;
  }

  @media (max-width: 1024px) {
    padding: 2rem 1rem;
    margin-bottom: 0px;
    align-items: center;

    h1 {
      font-size: 1.2rem;
    }
    h2 {
      font-size: 0.8rem;
    }
  }
`;

export const Line = styled.div`
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 0 2rem 0;

  @media (max-width: 1024px) {
    flex-direction: column;
    margin: 0;
  }
`;

export const Area = styled.div`
  float: left;
  width: 80%;
  display: flex;
  flex-direction: row;
  float: left;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const Card = styled.div`
  background: ${({ theme }) => theme.COLORS.DASH_CARD_BG};
  float: left;
  border-radius: 0.6rem;
  flex: 1;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.01);

  header {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5rem;
    align-items: center;

    h2 {
      font-size: 1.2rem;
      color: #fff;
    }

    span {
      float: left;
      font-size: 0.8rem;
      background: ${({ theme }) => theme.COLORS.DASH_CARD_TAG};
      border-radius: 0.2rem;
      padding: 0.5rem 1rem;
      color: #fff;
    }
  }

  @media (max-width: 1024px) {
    border-radius: 0;
    padding: 0;
    border-left: none;
    border-right: none;

    header {
      flex-direction: column;

      span {
        margin: 1rem 0 0 0;
        font-size: 0.8rem;
      }
    }

    table {
      width: 100%;
      min-width: 100%;
    }
  }
`;

export const Space = styled.div`
  width: 2rem;
  float: left;
  height: 100%;

  @media (max-width: 1024px) {
  }
`;

export const Totalizator = styled.div`
  float: left;
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 14rem;
  padding: 2rem;
  justify-content: space-between;

  h1 {
    font-size: 2.4rem;
    color: #fff;
  }

  small {
    color: #fff;
    opacity: 0.7;
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 0.4rem;
  }

  @media (max-width: 1024px) {
    height: auto;
    align-items: center;
    padding: 0;

    h1 {
      font-size: 2rem;
    }

    small {
      font-size: 0.8rem;
      margin: 1rem 0 2rem 0;
    }
  }
`;
