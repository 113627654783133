import React, { useState } from 'react';

import qs from 'qs';

import api from '~/services/api';

import { maskPrice } from '~/helpers/money';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  Totals,
  Counter,
  List,
  Card,
  Loading,
  Pagination,
  Mode,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
} from '~/components';

import Filtros from './Filtros';

import { FormDataProps } from './Filtros/interface';

import { TotalProps } from '~/components/Totals/interface';

import Order from '~/@types/Order';

import DetalhesDaMovimentacao from './DetalhesDaMovimentacao';

const Movimentacao: React.FC = () => {
  const [inLoad, setInLoad] = useState(false);

  const [listType, setListType] = useState('card');
  const [filtersVisible, setFiltersVisible] = useState(true);
  const [totalsVisible, setTotalsVisible] = useState(false);

  const [filtersTitle, setFiltersTitle] = useState<string | null>(null);
  const [filtersSubtitle, setFiltersSubtitle] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);

  const [filters, setFilters] = useState<FormDataProps | null>(null);
  const [items, setItems] = useState<Order[]>([]);
  const [totals, setTotals] = useState<TotalProps[]>([]);
  const [item, setItem] = useState<Order | null>(null);

  const onChangePage = (cPage: number) => {
    setItems([]);
    fetchResults(filters, cPage);
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const closeFilters = () => {
    setFiltersVisible(false);
  };

  const toggleTotals = () => {
    setTotalsVisible(!totalsVisible);
  };

  const calcTotalFilters = (filtros: FormDataProps, totalReg: number) => {
    let inFilters: string[] = [];
    if (filtros.cliente) {
      inFilters.push(`cliente`);
    }
    if (filtros.funcionario) {
      inFilters.push(`funcionário`);
    }
    if (filtros.tipo) {
      inFilters.push(`tipo`);
    }
    if (filtros.dtinicial) {
      inFilters.push(`data inicial`);
    }
    if (filtros.dtfinal) {
      inFilters.push(`data final`);
    }

    let message = null;
    if (inFilters.length) {
      const last = inFilters.pop();
      if (inFilters.length) {
        message = `Filtrando por: ${inFilters.join(', ')} e ${last}`;
      } else {
        message = `Filtrando por: ${last}`;
      }
    }

    setFiltersTitle(message);
    setFiltersSubtitle(`${totalReg} registros encontrados.`);
  };

  const onApplyFilters = async (filtros: FormDataProps) => {
    setFilters(filtros);
    fetchResults(filtros, 1);
  };

  const fetchResults = async (filtros: FormDataProps | null, cPage: number) => {
    try {
      setCurrentPage(cPage);

      if (cPage === 1) {
        setTotalPages(0);
        setTotalRegisters(0);
        setTotals([]);
        setItems([]);
      }

      setInLoad(true);

      let params = null;
      if (filtros) {
        params = qs.stringify({
          client: filtros.cliente,
          dateFinal: filtros.dtfinal,
          dateInitial: filtros.dtinicial,
          employee: filtros.funcionario,
          type: filtros.tipo,
          page: cPage,
          perPage: 20,
        });
      }

      const response = await api.get(
        `/sales/search${params ? `?${params}` : ''}`,
      );

      const { data } = response;

      const { data: items, total, pages, totalizadores } = data;
      if (items && items.length) {
        setItems(items);
        setTotalRegisters(total);
        setTotalPages(pages);
        setTotals([
          ...Object.getOwnPropertyNames(totalizadores).map(prop => ({
            label: `${prop}`,
            value: `${maskPrice(totalizadores[prop])}`,
          })),
        ]);
      }

      if (filtros) {
        calcTotalFilters(filtros, total);
      }

      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onReset = () => {
    setFiltersVisible(true);
  };

  const openItem = (item: Order) => {
    setItem(item);
  };

  const closeItem = () => {
    setItem(null);
  };

  return (
    <>
      {item ? <DetalhesDaMovimentacao item={item} onClose={closeItem} /> : null}

      <Gradient>
        <Container>
          <Menu title="Movimentação" />
          <Breadcrumb
            title="Movimentação"
            isSearch
            onSearch={(searchStr: string) => null}
            onToggleFilters={toggleFilters}
            filterStatus={filtersVisible}
          />
          <Filtros
            visible={filtersVisible}
            onApplyFilters={onApplyFilters}
            closeFilters={closeFilters}
          />

          {filtersTitle && filtersSubtitle ? (
            <Counter
              title={filtersTitle}
              subtitle={filtersSubtitle}
              onReset={onReset}
            />
          ) : null}

          {inLoad && <Loading />}

          {items.length ? (
            <Mode mode={listType} changeMode={mode => setListType(mode)} />
          ) : null}

          {listType === 'card' ? (
            <List>
              {items.map(item => (
                <Card
                  onClick={() => openItem(item)}
                  key={item.sequencia}
                  lines={[
                    [
                      {
                        label: 'Número:',
                        value: `${item.sequencia}`,
                      },
                      {
                        label: 'Data:',
                        value: `${item.Dataformated} ${item.Hora}`,
                      },
                      {
                        label: 'Tipo:',
                        value: `${item.Tipo}`,
                      },
                    ],
                    [
                      {
                        label: 'Tipo:',
                        value: `${item.SubTipo}`,
                      },
                      {
                        label: 'Cliente:',
                        value: `${item.CodCliente} - ${item.Cliente}`,
                      },
                    ],
                    [
                      {
                        label: 'Vendedor:',
                        value: `${item.Vendedor1}`,
                      },
                      {
                        label: 'Condição:',
                        value: `${item.Condicao}`,
                      },
                    ],
                    [
                      {
                        label: 'Total R$:',
                        value: `${maskPrice(Number(item.RSTotal))}`,
                      },
                      {
                        label: 'Custo R$:',
                        value: `${maskPrice(Number(item.RSCusto))}`,
                      },
                      {
                        label: 'Lucro %:',
                        value: `${maskPrice(Number(item.PercLucro))}`,
                      },
                      {
                        label: 'Lucro R$:',
                        value: `${maskPrice(Number(item.RSLucro))}`,
                      },
                    ],
                  ]}
                />
              ))}
            </List>
          ) : (
            <>
              {items.length ? (
                <Grid>
                  <GridLine stick>
                    <GridHeadItem title="Sequência" width={100} />
                    <GridHeadItem title="Data" />
                    <GridHeadItem title="Hora" />
                    <GridHeadItem title="Cliente" />
                    <GridHeadItem title="Vendedor" />
                    <GridHeadItem title="Tipo" />
                    <GridHeadItem title="Condição" />
                    <GridHeadItem title="Custo" />
                    <GridHeadItem title="Liquido" />
                    <GridHeadItem title="Lucro" />
                    <GridHeadItem title="Total" />
                    <GridHeadItem title="Lucro %" />
                    <GridHeadItem title="Markup %" />
                  </GridLine>

                  {items.map(item => (
                    <GridLine
                      key={item.sequencia}
                      onClick={() => openItem(item)}
                    >
                      <GridItem width={100}>
                        <small>{item.sequencia}</small>
                      </GridItem>
                      <GridItem>{item.Dataformated}</GridItem>
                      <GridItem>{item.Hora}</GridItem>
                      <GridItem>
                        <strong>{item.Cliente}</strong>
                      </GridItem>
                      <GridItem>{item.Vendedor1}</GridItem>
                      <GridItem>{item.Tipo}</GridItem>
                      <GridItem>{item.Condicao}</GridItem>
                      <GridItem>{item.RSCusto}</GridItem>
                      <GridItem>{item.RSTotalLiquido}</GridItem>
                      <GridItem>{item.RSLucro}</GridItem>
                      <GridItem>{item.RSTotal}</GridItem>
                      <GridItem>{item.PercLucro}</GridItem>
                      <GridItem>{item.PercMarkup}</GridItem>
                    </GridLine>
                  ))}
                </Grid>
              ) : null}
            </>
          )}

          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRegisters={totalRegisters}
              onChangePage={onChangePage}
            />
          ) : null}

          <Totals
            visible={totalsVisible}
            onToggle={toggleTotals}
            items={totals}
          />
        </Container>
      </Gradient>
    </>
  );
};

export default Movimentacao;
