import styled from 'styled-components';

export const Content = styled.div`
  padding: 0px 0px 0px 340px;
  float: left;
  width: 100%;
  overflow-y: auto;
  height: 100%;

  @media (max-width: 1024px) {
    padding: 5rem 0 0 0;
    overflow-x: hidden;
  }
`;
