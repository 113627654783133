import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api-gerencial.sisauttecnologia.com.br',
  // baseURL: 'http://localhost:3000',
});

api.defaults.timeout = 120000;

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('@SisautDash:token');
      localStorage.removeItem('@SisautDash:user');
    } else {
      return Promise.reject(error);
    }
  },
);

export default api;
