import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

import api from '~/services/api';

import { Gradient, Container, Menu, Loading, Breadcrumb } from '~/components';

import { Content } from './styles';

import CashboxItens from '~/@types/CashboxItens';
import { maskPrice } from '~/helpers/money';

const DetalhesCaixa: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [inLoad, setInLoad] = useState(true);

  const [items, setItems] = useState<CashboxItens[]>([]);

  useEffect(() => {
    const fetchOrder = async (pId: string) => {
      try {
        setInLoad(true);

        const response = await api.get(`finantial/cashbox/${pId}`);
        const { data } = response;
        if (data) {
          setItems(data.data);
        }
        setInLoad(false);
      } catch (e) {
        setInLoad(false);
      }
    };

    if (id) {
      fetchOrder(id);
    }
  }, [id]);

  return (
    <>
      <Gradient>
        <Container>
          <Menu title={`Detalhes do caixa: ${id}`} />
          <Breadcrumb title={`Detalhes do caixa: ${id}`} />
          {inLoad && <Loading />}

          <Content>
            <main>
              <h1>Registros</h1>
              {/* <div>
                <p>
                  <span>
                    <strong>Número:</strong>
                    <small>{id}</small>
                  </span>
                </p>
              </div> */}
            </main>

            {items.length ? (
              <main>
                <div>
                  {items.map(item => (
                    <p>
                      <span>
                        <strong>Data:</strong>
                        <small>{item.Data}</small>
                      </span>
                      <span>
                        <strong>Descrição:</strong>
                        <small>{item.Descricao}</small>
                      </span>
                      <span>
                        <strong>Forma:</strong>
                        <small>{item.dsDescricao}</small>
                      </span>
                      <span>
                        <strong>Valor R$:</strong>
                        <small>{maskPrice(item.Valor)}</small>
                      </span>
                      <span>
                        <strong>Funcionario:</strong>
                        <small>{item.nome}</small>
                      </span>
                      <span>
                        <strong>Pedido:</strong>
                        <small>{item.nvenda}</small>
                      </span>
                    </p>
                  ))}
                </div>
              </main>
            ) : null}
          </Content>
        </Container>
      </Gradient>
    </>
  );
};

export default DetalhesCaixa;
