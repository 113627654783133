import styled from 'styled-components';

export const Container = styled.button`
  width: 100%;
  float: left;
  background: ${({ theme }) => theme.COLORS.CARD_BG};

  border-radius: 0.4rem;
  padding: 1.5rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  display: flex;
  flex-direction: column;

  &:hover {
    background: ${({ theme }) => theme.COLORS.CARD_BG_ACTIVE};
  }

  @media (max-width: 1024px) {
  }
`;

export const Line = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  @media (max-width: 1024px) {
  }
`;

export const Item = styled.div`
  display: flex;
  flex-direction: row;
  float: left;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;

  margin-right: 1rem;

  &:last-child {
    margin-right: 0;
  }

  span {
    color: ${({ theme }) => theme.COLORS.CARD_LABEL};
    font-weight: 700;
    font-size: 1.2rem;
  }

  strong {
    color: ${({ theme }) => theme.COLORS.CARD_VALUE};

    font-size: 1.4rem;
    font-weight: normal;
    padding-left: 0.4rem;
  }

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: flex-start;

    span {
      font-size: 0.8rem;
    }

    strong {
      font-size: 1rem;
    }
  }
`;
