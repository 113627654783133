import styled from 'styled-components';

export const Anchor = styled.button.attrs({ type: 'button' })`
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background: ${({ theme }) => theme.COLORS.TOTALS_ANCHOR_BG};

  border: 5px solid rgba(255, 255, 255, 0.2);
  margin: -2.6rem 0 0 0;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  position: fixed;
  right: 2rem;
  bottom: 2rem;

  @media (max-width: 1024px) {
    right: 1rem;
    bottom: 1rem;
  }
`;

export const Resume = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  padding: 1rem;
  position: fixed;
  bottom: 0rem;

  left: 340px;
  width: calc(100% - 340px);

  background: ${({ theme }) => theme.COLORS.TOTALS_RESUME_BG};

  border-top: 5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  z-index: 3000;

  @media (max-width: 1024px) {
    left: 0px;
    width: 100%;
  }

  main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    i {
      width: 3.6rem;
      height: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: ${({ theme }) => theme.COLORS.TOTALS_ANCHOR_BG};
      border: 5px solid rgba(255, 255, 255, 0.2);
      margin: -2.6rem 0 0 0;
    }

    h1 {
      color: ${({ theme }) => theme.COLORS.TOTALS_TEXT};
      font-size: 1.2rem;
      margin: 1rem 0;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    float: left;
    width: 100%;
    margin-bottom: 1rem;

    span {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      border-bottom: 2px solid rgba(255, 255, 255, 0.2);
      color: ${({ theme }) => theme.COLORS.TOTALS_TEXT};
    }

    span:last-child {
      border-bottom: none;
    }

    strong {
      border-right: 2px solid rgba(255, 255, 255, 0.2);
      padding: 0.5rem 1rem;
      color: ${({ theme }) => theme.COLORS.TOTALS_TEXT};
      font-size: 1rem;
    }

    small {
      width: 50%;
      float: left;
      padding-left: 1rem;
      font-size: 1rem;

      @media (max-width: 1024px) {
        width: 30%;
      }
    }
  }

  button {
    float: left;
    width: 100%;
    border-radius: 0.4rem;
    background: ${({ theme }) => theme.COLORS.TOTALS_CLOSE_BG};
    padding: 1rem;
    color: ${({ theme }) => theme.COLORS.TOTALS_TEXT};
    font-size: 1.2rem;
    font-weight: bold;
    width: auto;
    align-self: center;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;
