import styled from 'styled-components';

export const Content = styled.main`
  padding: 0px 0px 0px 340px;
  min-height: 600px;

  @media (max-width: 1024px) {
    padding: 2.5rem 0 0 0;
    overflow-x: hidden;
  }
`;

export const SubMenu = styled.div`
  float: left;
  width: 100%;
  flex-direction: row;
`;

export const SubMenuBtn = styled.button`
  font-size: 1.2rem;
  color: ${({ theme }) => theme.COLORS.TEXT};
  background: rgba(255, 255, 255, 0.02);
  border-radius: 1rem;
  align-self: flex-start;
  float: left;
  display: flex;
  font-weight: 700;
  padding: 1rem 2rem;
  transition: all 0.5s ease;
  margin-right: 1rem;

  &.active,
  &:hover {
    background: rgba(255, 255, 255, 0.1);
    text-decoration: underline;
  }

  @media (max-width: 1024px) {
    font-size: 0.6rem;
    padding: 0.5rem;
  }
`;
