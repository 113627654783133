import React from 'react';

import { Container } from './styles';

import { ItemProps } from './interface';

const GridItem: React.FC<ItemProps> = ({
  color,
  width,
  children,
  ...props
}) => {
  return (
    <Container width={width} color={color} {...props}>
      {children}
    </Container>
  );
};

export default GridItem;
