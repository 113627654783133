import React from 'react';

import { MdArrowDropDownCircle } from 'react-icons/md';
import { AiFillCloseCircle } from 'react-icons/ai';

import { Content } from './styles';

import { DocketProps } from './interface';

const Docket: React.FC<DocketProps> = ({ label, value, onClear, onClick }) => {
  return (
    <Content>
      <div onClick={onClick}>
        {value ? (
          <>
            <small>{label}</small>
            <strong>{value}</strong>
          </>
        ) : (
          <strong>{label}</strong>
        )}
      </div>
      {value ? (
        <button type="button" onClick={onClear}>
          <AiFillCloseCircle size={22} color="#fff" />
        </button>
      ) : null}
      <main>
        <button type="button" onClick={onClick}>
          <MdArrowDropDownCircle size={28} color="#fff" />
        </button>
      </main>
    </Content>
  );
};

export default Docket;
