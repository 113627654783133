import React, { useState, useRef } from 'react';

import { FaSearch } from 'react-icons/fa';
import { AiFillCloseSquare, AiOutlineClose } from 'react-icons/ai';
import { BsArrowRight } from 'react-icons/bs';

import Loading from '../Loading';
import {
  Content,
  Header,
  SearchBar,
  Search,
  Button,
  Input,
  List,
  Item,
} from './styles';

import { FinderProps, ItemProps } from './interface';

const Finder: React.FC<FinderProps> = ({
  title,
  onSearch,
  onClose,
  items,
  onSelectItem,
  inLoad,
  isSearch,
}) => {
  const formRef = useRef(null);

  const [searchStr, setSearchStr] = useState<string>('');

  const onSubmit = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    if (onSearch) {
      onSearch(searchStr);
    }
  };

  const onKeyDownInput = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (onSearch) {
        onSearch(searchStr);
      }
    }
  };

  const selectItem = (item: ItemProps) => {
    onSelectItem(item);
    onClose();
  };

  return (
    <Content>
      <Header>
        <h1>{title}</h1>
        <Button onClick={onClose}>
          <AiOutlineClose size={32} color="#fff" />
        </Button>
      </Header>
      {isSearch && onSearch ? (
        <SearchBar>
          <Search onSubmit={onSubmit} ref={formRef}>
            <div>
              <Input
                placeholder="Busca"
                value={searchStr}
                onChange={e => setSearchStr(e.target.value.toUpperCase())}
                onKeyDown={onKeyDownInput}
                autoCapitalize="characters"
                autoFocus
              />
              {searchStr ? (
                <Button onClick={() => setSearchStr('')}>
                  <AiFillCloseSquare size={22} color={'#fff'} />
                </Button>
              ) : null}
            </div>
            <Button type="submit">
              <FaSearch size={22} color={'#fff'} />
            </Button>
          </Search>
        </SearchBar>
      ) : null}

      {inLoad && <Loading />}

      <List>
        {items.map(item => (
          <Item onClick={() => selectItem(item)} key={item.value}>
            <span>{item.label}</span>
            <BsArrowRight size={32} color="#fff" />
          </Item>
        ))}
      </List>
    </Content>
  );
};

export default Finder;
