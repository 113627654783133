import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1rem;
  float: left;
  width: 100%;
  margin-bottom: 5rem;

  main {
    display: flex;
    flex-direction: row;
    background: ${({ theme }) => theme.COLORS.PAGINATION_BG};
    margin: 0;
    float: left;
    width: 100%;
    border-radius: 0.4rem;
    padding: 1rem;
  }

  p {
    display: flex;
    flex-direction: column;
    float: left;
    width: 100%;
    max-width: 20rem;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 0.6rem;
    padding: 1rem 0 0.5rem 0.8rem;
  }

  strong {
    font-size: 1.2rem;
    font-weight: normal;
    color: #fff;

    span {
      background: #fff;
      border-radius: 0.4rem;
      padding: 0.2rem 1rem;
      cursor: pointer;

      color: #333;
      font-weight: 700;
      font-size: 1.4rem;
    }
  }

  small {
    color: #fff;
    opacity: 0.6;
    font-size: 0.8rem;
    margin: 0.5rem 0 0 0;
  }

  div {
    display: flex;
    flex-direction: row;
  }

  button {
    background: none;
    margin-left: 0.5rem;
    background: rgba(0, 0, 0, 0.2);
    padding: 1rem;
    border-radius: 0.6rem;
  }

  @media (max-width: 1024px) {
    overflow-x: hidden;

    main {
      flex-direction: column;
      padding: 0.5rem;
    }

    p {
      padding: 2rem 1rem;
    }

    div {
      margin: 0.5rem 0 0 0;
      width: 100%;

      button {
        flex: 1;
        margin: 0;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }
  }
`;
