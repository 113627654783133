import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import api from '~/services/api';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  List,
  Card,
  Loading,
  Mode,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
  Plus,
  Pagination
} from '~/components';

import qs from 'qs';

import Companie from '~/@types/Companie';

const Empresas: React.FC = () => {
  const navigate = useNavigate();

  const [inLoad, setInLoad] = useState(false);

  const [listType, setListType] = useState('card');
  const [items, setItems] = useState<Companie[]>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const onSearch = async (pSearchStr: string) => {
    try {
      setInLoad(true);

      const response = await api.get(`/companies?nome=${pSearchStr}`);

      const { data } = response;

      const { data: items } = data;
      if (items && items.length) {
        setItems(items);
      }

      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const fetchCompanies = async (cPage: number) => {
    try {
      setInLoad(true);

      setCurrentPage(cPage);

      if (cPage === 1) {
        setTotalPages(0);
        setTotalRegisters(0);
        setItems([]);
      }

      let params = null;
      params = qs.stringify({
        page: cPage,
        perPage: 10,
      });


      const response = await api.get(`/companies${params ? `?${params}` : ''}`);

      const { data } = response;

      const { data: items, total, pages } = data;
      if (items && items.length) {
        setItems(items);
        setTotalRegisters(total);
        setTotalPages(pages);
      }

      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  useEffect(() => {
    fetchCompanies(1);
  }, []);

  const onChangePage = (cPage: number) => {
    setItems([]);
    fetchCompanies(cPage);
  };

  return (
    <>
      <Plus onClick={() => navigate('/empresa/0')} />
      <Gradient>
        <Container>
          <Menu title="Empresas" />
          <Breadcrumb title="Empresas" isSearch onSearch={onSearch} />

          {inLoad && <Loading />}

          {items.length ? (
            <Mode mode={listType} changeMode={mode => setListType(mode)} />
          ) : null}

          {listType === 'card' ? (
            <List>
              {items.map(item => (
                <Card
                  onClick={() => navigate(`/empresa/${item.id}`)}
                  key={item.idempresa}
                  lines={[
                    [
                      {
                        label: 'Código:',
                        value: `${item.idempresa}`,
                      },
                      {
                        label: 'Ativo:',
                        value: `${item.ativo === '1' ? 'Sim' : 'Não'}`,
                      },
                    ],
                    [
                      {
                        label: 'Nome:',
                        value: `${item.nome}`,
                      },
                    ],
                    [
                      {
                        label: 'Matriz:',
                        value: `${item.idmatriz}`,
                      },
                    ],
                  ]}
                />
              ))}
            </List>
          ) : (
            <>
              {items.length ? (
                <Grid>
                  <GridLine stick>
                    <GridHeadItem title="Código" width={100} />
                    <GridHeadItem title="Nome" />
                    <GridHeadItem title="Matriz" />
                    <GridHeadItem title="Ativo" />
                  </GridLine>

                  {items.map(item => (
                    <GridLine
                      key={item.idempresa}
                      onClick={() => navigate(`/empresa/${item.id}`)}
                    >
                      <GridItem width={100}>
                        <small>{item.idempresa}</small>
                      </GridItem>
                      <GridItem>
                        <strong>{item.nome}</strong>
                      </GridItem>
                      <GridItem>{item.idmatriz}</GridItem>
                      <GridItem>{item.ativo === '1' ? 'Sim' : 'Não'}</GridItem>
                    </GridLine>
                  ))}
                </Grid>
              ) : null}
            </>
          )}
          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRegisters={totalRegisters}
              onChangePage={onChangePage}
            />
          ) : null}
        </Container>
      </Gradient>
    </>
  );
};

export default Empresas;
