import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  float: left;
  height: 100%;
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 1.5rem;
  align-items: center;

  div {
    float: left;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    align-items: center;
    padding: 2rem 0;

    img {
      max-height: 10rem;
      margin-bottom: 1rem;
    }
  }

  h1 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.COLORS.TEXT};
  }

  @media (max-width: 1024px) {
    div {
      img {
        max-height: 6rem;
      }
    }
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  padding: 0 1.5rem;

  @media (max-width: 1024px) {
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 0 2rem 0;
  flex-direction: column;
  align-items: center;

  div {
    width: 100%;
    float: left;
    justify-content: space-between;
    display: flex;
    padding: 0 2rem;
  }

  a {
    font-size: 1rem;
    color: ${({ theme }) => theme.COLORS.TEXT};
  }

  small {
    color: #fff;
    opacity: 0.7;
    margin: 2rem 0 0 0;
  }

  @media (max-width: 1024px) {
  }
`;
