import React, { useState } from 'react';

import { BsArrowUpSquareFill } from 'react-icons/bs';
import { BiSolidUpArrow } from 'react-icons/bi';

import { useTheme } from 'styled-components';

import { Button, Stack } from '@mui/material';

import { Form, Formik } from 'formik';

import api from '~/services/api';

import { Finder, Docket, Loading } from '~/components';

import {
  Content,
  Header,
  Group,
  Space,
  Close,
  Clear,
  Footer,
  Dismiss,
} from './styles';

import { FormDataProps } from './interface';

import schema from './schema';

import { FiltrosProps } from './interface';
import { ItemProps } from '~/components/Finder/interface';

import 'dayjs/locale/pt-br';

const initialValues: FormDataProps = {
  produto: '',
  grupo: '',
  marca: '',
  secao: '',
  estoque: '',
  deposito: '',
};

const Filtros: React.FC<FiltrosProps> = ({
  visible,
  onApplyFilters,
  closeFilters,
}) => {
  const { COLORS } = useTheme();

  const [inLoad, setInLoad] = useState(false);

  const [products, setProducts] = useState<ItemProps[]>([]);
  const [product, setProduct] = useState<ItemProps | null>(null);
  const [productsVisible, setProductsVisible] = useState(false);

  const [comercializavel, setComercializavel] = useState<ItemProps | null>(null);
  const [comercializavelVisible, setComercializavelVisible] = useState(false);

  const [groups, setGroups] = useState<ItemProps[]>([]);
  const [group, setGroup] = useState<ItemProps | null>(null);
  const [groupsVisible, setGroupsVisible] = useState(false);

  const [brands, setBrands] = useState<ItemProps[]>([]);
  const [brand, setBrand] = useState<ItemProps | null>(null);
  const [brandsVisible, setBrandsVisible] = useState(false);

  const [sessions, setSessions] = useState<ItemProps[]>([]);
  const [session, setSession] = useState<ItemProps | null>(null);
  const [sessionsVisible, setSessionsVisible] = useState(false);

  const [stockVisible, setStockVisible] = useState(false);
  const [stock, setStock] = useState<ItemProps | null>(null);

  const [depositVisible, setDepositVisible] = useState(false);
  const [deposit, setDeposit] = useState<ItemProps | null>(null);

  const onSearchProducts = async (pStrSearch: string) => {
    try {
      setInLoad(true);
      const response = await api.post('/product/show', {
        filter: pStrSearch,
      });
      const { data } = response;
      if (data) {
        const { data: fetchedItems } = data;
        if (fetchedItems && fetchedItems.length) {
          const items: Array<{ codigo: number; nome: string }> = [
            ...fetchedItems,
          ];
          setProducts([
            ...items.map(item => ({
              label: item.nome,
              value: `${item.codigo}`,
            })),
          ]);
        }
      }
      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onSearchGroups = async (pStrSearch: string) => {
    try {
      setInLoad(true);
      const response = await api.post('/product/group/show', {
        filter: pStrSearch,
      });
      const { data } = response;
      if (data && data.length) {
        const items: Array<{ codigo: number; nome: string }> = [...data];
        setGroups([
          ...items.map(item => ({ label: item.nome, value: `${item.nome}` })),
        ]);
      }
      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onSearchBrands = async (pStrSearch: string) => {
    try {
      setInLoad(true);
      const response = await api.post('/product/mark/show', {
        filter: pStrSearch,
      });
      const { data } = response;
      if (data && data.length) {
        const items: Array<{ codigo: number; nome: string }> = [...data];
        setBrands([
          ...items.map(item => ({ label: item.nome, value: `${item.nome}` })),
        ]);
      }
      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onSearchSessions = async (pStrSearch: string) => {
    try {
      setInLoad(true);
      const response = await api.post('/product/section/show', {
        filter: pStrSearch,
      });
      const { data } = response;
      if (data && data.length) {
        const items: Array<{ codigo: number; nome: string }> = [...data];
        setSessions([
          ...items.map(item => ({ label: item.nome, value: `${item.nome}` })),
        ]);
      }
      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const submitFilters = (data: FormDataProps) => {
    onApplyFilters({
      ...data,

      produto: product ? product.value : null,
      grupo: group ? group.value : null,
      marca: brand ? brand.value : null,
      secao: session ? session.value : null,

      deposito: deposit ? deposit.value : null,
      estoque: stock ? stock.value : null,
      tipo: comercializavel && comercializavel.value === 'sim' ? 'MERCADORIA PARA REVENDA' : undefined,
    });
    closeFilters();
  };

  const resetFilters = () => {
    alert('resetar filtros');
  };

  return (
    <>
      {productsVisible ? (
        <Finder
          title="Produto"
          onClose={() => setProductsVisible(false)}
          onSearch={onSearchProducts}
          onSelectItem={cItem => setProduct(cItem)}
          items={products}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      {groupsVisible ? (
        <Finder
          title="Grupo"
          onClose={() => setGroupsVisible(false)}
          onSearch={onSearchGroups}
          onSelectItem={cItem => setGroup(cItem)}
          items={groups}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      {brandsVisible ? (
        <Finder
          title="Marca"
          onClose={() => setBrandsVisible(false)}
          onSearch={onSearchBrands}
          onSelectItem={cItem => setBrand(cItem)}
          items={brands}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      {sessionsVisible ? (
        <Finder
          title="Sessão"
          onClose={() => setSessionsVisible(false)}
          onSearch={onSearchSessions}
          onSelectItem={cItem => setSession(cItem)}
          items={sessions}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      {stockVisible ? (
        <Finder
          title="Estoque"
          onClose={() => setStockVisible(false)}
          onSelectItem={cItem => setStock(cItem)}
          items={[
            {
              label: 'Menor',
              value: 'menor',
            },
            {
              label: 'Maior',
              value: 'maior',
            },
          ]}
          isSearch
        />
      ) : null}

      {depositVisible ? (
        <Finder
          title="Depósito"
          onClose={() => setDepositVisible(false)}
          onSelectItem={cItem => setDeposit(cItem)}
          items={[
            {
              label: 'Menor',
              value: 'menor',
            },
            {
              label: 'Maior',
              value: 'maior',
            },
          ]}
          isSearch
        />
      ) : null}

      {comercializavelVisible ? (
        <Finder
          title="Comercializável"
          onClose={() => setComercializavelVisible(false)}
          onSelectItem={cItem => setComercializavel(cItem)}
          items={[
            {
              label: 'Sim',
              value: 'sim',
            },
            {
              label: 'Todos',
              value: 'todos',
            },
          ]}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      <Content visible={visible}>
        <Header>
          <Close onClick={closeFilters}>
            <BsArrowUpSquareFill size={32} color="#fff" />
          </Close>
          <h1>Filtros</h1>
        </Header>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              submitFilters(values);
            }, 500);
          }}
          validationSchema={schema}
        >
          {({ submitForm, isSubmitting }) => (
            <Form style={{ width: '100%', maxWidth: '60rem' }}>
              <Group>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={'1.5rem'}
                >
                  <Docket
                    label="Produto"
                    value={product?.label}
                    onClick={() => setProductsVisible(!productsVisible)}
                    onClear={() => setProduct(null)}
                  />
                </Stack>

                <Space />

                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={'1.5rem'}
                >
                  <Docket
                    label="Grupo"
                    value={group?.label}
                    onClick={() => setGroupsVisible(!groupsVisible)}
                    onClear={() => setGroup(null)}
                  />
                </Stack>
              </Group>
              <Group>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={'1.5rem'}
                >
                  <Docket
                    label="Marca"
                    value={brand?.label}
                    onClick={() => setBrandsVisible(!brandsVisible)}
                    onClear={() => setBrand(null)}
                  />
                </Stack>

                <Space />

                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={'1.5rem'}
                >
                  <Docket
                    label="Sessão"
                    value={session?.label}
                    onClick={() => setSessionsVisible(!sessionsVisible)}
                    onClear={() => setSession(null)}
                  />
                </Stack>
              </Group>

              <Group>
                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={'1.5rem'}
                >
                  <Docket
                    label="Estoque"
                    value={stock?.label}
                    onClick={() => setStockVisible(!stockVisible)}
                    onClear={() => setStock(null)}
                  />
                </Stack>

                <Space />

                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={'1.5rem'}
                >
                  <Docket
                    label="Depósito"
                    value={deposit?.label}
                    onClick={() => setDepositVisible(!depositVisible)}
                    onClear={() => setDeposit(null)}
                  />
                </Stack>

                <Stack
                  flexDirection={'row'}
                  alignItems={'center'}
                  width={'100%'}
                  mb={'1.5rem'}
                  marginLeft={'0.6rem'}
                >
                  <Docket
                    label="Comercializável"
                    value={comercializavel?.label}
                    onClick={() => setComercializavelVisible(!comercializavelVisible)}
                    onClear={() => setComercializavel(null)}
                  />
                </Stack>
              </Group>

              <Stack
                flexDirection={'row'}
                width={'100%'}
                justifyContent={'space-between'}
                marginTop={'2rem'}
              >
                <Clear onClick={resetFilters}>Resetar</Clear>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  disableElevation
                  onClick={submitForm}
                  variant="contained"
                  size="large"
                  style={{
                    fontSize: '1.4rem',
                    fontWeight: '700',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    background: COLORS.BUTTON_BG,
                  }}
                >
                  FILTRAR
                </Button>
              </Stack>

              {isSubmitting && <Loading />}
            </Form>
          )}
        </Formik>
      </Content>
      {visible ? (
        <Footer>
          <Dismiss onClick={closeFilters}>
            <BiSolidUpArrow size={18} color="#fff" />
            <span>Fechar</span>
          </Dismiss>
        </Footer>
      ) : null}
    </>
  );
};

export default Filtros;
