import React from 'react';

import { Container } from './styles';

import { ItemProps } from './interface';

const GridHeadItem: React.FC<ItemProps> = ({
  title,
  width,
  onClick,
  active,
  children,
}) => {
  return (
    <Container active={active} width={width} onClick={onClick}>
      {title ? <span>{title}</span> : null}
      {children ? children : null}
    </Container>
  );
};

export default GridHeadItem;
