import React from 'react';

import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';

import { Container } from './styles';

import { CheckboxProps } from './interface';

const Checkbox: React.FC<CheckboxProps> = ({ active, label, onClick }) => {
  return (
    <Container onClick={onClick} type="button">
      {active ? (
        <ImCheckboxChecked size={32} color="#fff" />
      ) : (
        <ImCheckboxUnchecked size={32} color="#fff" />
      )}
      <span>{label}</span>
    </Container>
  );
};

export default Checkbox;
