import React from 'react';

import { HashRouter as Router } from 'react-router-dom';

import { ThemeProvider } from 'styled-components';

import { ToastContainer } from 'react-toastify';

import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

import GlobalStyle from './styles/global';
import MuiGlobalTheme from '~/styles/mui-global';

import * as themes from './styles/themes';

import Routes from './routes';

import useAppTheme from './hooks/theme';

import { AuthProvider } from '~/hooks/auth';
import { AdminProvider } from '~/hooks/admin';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import 'dayjs/locale/pt-br';

import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const { currentTheme } = useAppTheme();

  return (
    <ThemeProvider theme={themes[currentTheme]}>
      <Router>
        <AuthProvider>
          <AdminProvider>
            <ToastContainer />
            <MuiThemeProvider theme={MuiGlobalTheme}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
                localeText={{
                  cancelButtonLabel: 'Cancelar',
                  clearButtonLabel: 'Limpar',
                  okButtonLabel: 'Selecionar',
                  todayButtonLabel: 'Hoje',
                }}
              >
                <Routes />
              </LocalizationProvider>
            </MuiThemeProvider>
          </AdminProvider>
        </AuthProvider>
      </Router>
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default App;
