import React from 'react';

import { Container, Content } from './styles';

import { AreaProps } from './interface';

const Area: React.FC<AreaProps> = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};

export default Area;
