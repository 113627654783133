import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  float: left;
  width: 100%;
  padding: 0 1rem;

  @media (max-width: 1024px) {
  }
`;
