import styled from 'styled-components';

import { ContainerProps } from './interface';

export const Scroll = styled.div`
  float: left;
  width: 100%;

  @media (max-width: 1024px) {
    table {
      min-width: 300%;
    }

    overflow-x: scroll;
  }
`;

export const Container = styled.table<ContainerProps>`
  float: left;
  width: 100%;

  background: ${({ theme }) => theme.COLORS.GRID_BG};
`;
