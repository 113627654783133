import styled from 'styled-components';

export const Container = styled.button.attrs({
  type: 'button',
})`
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  box-shadow: inset 1px 1px 5px rgba(0, 0, 0, 0.1);
  float: left;
  padding: 15px;
  border-radius: 100%;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.COLORS.PLUS_BG};
  transition: all 0.5s ease;
  margin-left: 10px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  font-size: 14px;
  z-index: 3000;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2);

  &:hover {
    background: ${({ theme }) => theme.COLORS.PLUS_BG_HOVER};
  }
`;
