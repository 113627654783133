import React from 'react';

import { AiFillCloseCircle } from 'react-icons/ai';

import { Container } from './styles';

import { CounterProps } from './interface';

const Counter: React.FC<CounterProps> = ({ title, subtitle, onReset }) => {
  return (
    <Container>
      <main>
        <div>
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
        </div>
        <button type="button" onClick={onReset}>
          <AiFillCloseCircle size={32} color="#fff" />
        </button>
      </main>
    </Container>
  );
};

export default Counter;
