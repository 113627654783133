import * as yup from 'yup';

const schema = yup.object({
  senhaantiga: yup.string()
    .required('Informe a senha')
    .min(3, 'A senha no mínimo 3 dígitos'),
  novasenha: yup.string()
    .required('Informe a senha')
    .min(3, 'A senha no mínimo 3 dígitos'),
  confirmarnovasenha: yup
    .string()
    .oneOf([yup.ref('novasenha'), ''], 'Confirmação de senha inválida')
});

export default schema;
