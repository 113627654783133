import React from 'react';
import { Button, Stack } from '@mui/material';
import {
  Gradient,
  Container,
  Menu,
  Loading,
  Area,
  Breadcrumb,
} from '~/components';
import { Field, Form, Formik } from 'formik';
import { FormDataProps } from './interface';
import { useTheme } from 'styled-components';
import { TextField } from 'formik-material-ui';
import { toast } from 'react-toastify';

import api from '~/services/api';

import schema from './schema';

import { Header, Title } from './styles';
import { useAuth } from '~/hooks/auth';

const initialValues: FormDataProps = {
  senhaantiga: '',
  novasenha: '',
  confirmarnovasenha: '',
};

const MinhaConta: React.FC = () => {
  const { COLORS } = useTheme();
  const { user } = useAuth();

  const handlerChangePassword = (formData: FormDataProps) => {
    return new Promise((resolve, reject) => {
      const handler = async () => {
        try {
          const response = await api.post(
            '/users/changepassword',
            {
              userId: user.id,
              newPassword: formData.novasenha,
              oldPassword: formData.senhaantiga,
            },
            {
              headers: {
                idempresa: user.idempresa,
              },
            },
          );

          if (response.status !== 200) {
            toast.error(`Falha ao alterar a senha!`);
            return resolve(true);
          }

          toast.success(`Senha alterada com sucesso!`);

          resolve(true);
        } catch (e) {
          toast.error(`${(e as any).response.data.err}`);
          resolve(true);
        }
      };
      handler();
    });
  };

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Minha Conta" />
          <Breadcrumb title="Minha Conta" />

          <Area>
            <Title>MINHAS INFORMAÇÕES:</Title>

            <Header>
              <div className="user">
                <h2>
                  <span>Usuário Logado:</span> {user.userName}
                </h2>
                <h2>
                  <span>Empresa:</span> {user.empresa}
                </h2>
              </div>
            </Header>

            <Title>ALTERAR SENHA:</Title>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values, { setSubmitting }) => {
                await handlerChangePassword(values);
                setSubmitting(false);
              }}
              validationSchema={schema}
            >
              {({ submitForm, isSubmitting }) => (
                <Form style={{ width: '100%' }}>
                  <Stack flexDirection={'column'} width={'100%'} mb={'1.5rem'}>
                    <Field
                      component={TextField}
                      type="password"
                      label="Senha antiga"
                      name="senhaantiga"
                      variant="outlined"
                      InputProps={{
                        placeholder: 'Senha antiga',
                      }}
                    />
                  </Stack>

                  <Stack flexDirection={'column'} width={'100%'} mb={'1.5rem'}>
                    <Field
                      component={TextField}
                      type="password"
                      label="Nova senha"
                      name="novasenha"
                      variant="outlined"
                      InputProps={{
                        placeholder: 'Nova senha',
                      }}
                    />
                  </Stack>

                  <Stack flexDirection={'column'} width={'100%'} mb={'1.5rem'}>
                    <Field
                      component={TextField}
                      type="password"
                      label="Confirmar nova senha"
                      name="confirmarnovasenha"
                      variant="outlined"
                      InputProps={{
                        placeholder: 'Confirmar nova senha',
                      }}
                    />
                  </Stack>

                  <Stack
                    flexDirection={'row'}
                    width={'100%'}
                    justifyContent={'flex-end'}
                  >
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      disableElevation
                      onClick={submitForm}
                      variant="contained"
                      size="large"
                      style={{
                        fontSize: '1.4rem',
                        fontWeight: '700',
                        paddingTop: '1rem',
                        paddingBottom: '1rem',
                        background: COLORS.BUTTON_BG,
                      }}
                    >
                      SALVAR
                    </Button>
                  </Stack>

                  {isSubmitting && <Loading />}
                </Form>
              )}
            </Formik>
          </Area>
        </Container>
      </Gradient>
    </>
  );
};

export default MinhaConta;
