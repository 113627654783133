import React, { useState } from 'react';

import { BsArrowUpSquareFill } from 'react-icons/bs';
import { BiSolidUpArrow } from 'react-icons/bi';

import { useTheme } from 'styled-components';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Form, Formik } from 'formik';

import api from '~/services/api';

import { Finder, Docket, Loading } from '~/components';

import {
  Content,
  Header,
  Group,
  Space,
  Close,
  Clear,
  Footer,
  Dismiss,
} from './styles';

import { FormDataProps } from './interface';

import schema from './schema';

import { FiltrosProps } from './interface';
import { ItemProps } from '~/components/Finder/interface';

import 'dayjs/locale/pt-br';

const initialValues: FormDataProps = {
  cliente: '',
  funcionario: '',
  tipo: '',
  dtfinal: '',
  dtinicial: '',
};

const Filtros: React.FC<FiltrosProps> = ({
  visible,
  onApplyFilters,
  closeFilters,
}) => {
  const { COLORS } = useTheme();

  const [inLoad, setInLoad] = useState(false);

  const [dtInicial, setDtinicial] = useState<Dayjs | null>(dayjs(new Date()));
  const [dtFinal, setDtfinal] = useState<Dayjs | null>(dayjs(new Date()));

  const [clients, setClients] = useState<ItemProps[]>([]);
  const [client, setClient] = useState<ItemProps | null>(null);
  const [clientsVisible, setClientsVisible] = useState(false);

  const [employees, setEmployees] = useState<ItemProps[]>([]);
  const [employee, setEmployee] = useState<ItemProps | null>(null);
  const [employeesVisible, setEmployeesVisible] = useState(false);

  const [types, setTypes] = useState<ItemProps[]>([]);
  const [typeVisible, setTypeVisible] = useState(false);
  const [type, setType] = useState<ItemProps | null>(null);

  const onSearchClient = async (pStrSearch: string) => {
    try {
      setInLoad(true);
      const response = await api.post('/client/show', {
        filter: pStrSearch,
      });
      const { data } = response;
      if (data) {
        const { data: fetchedItems } = data;
        if (fetchedItems && fetchedItems.length) {
          const items: Array<{ codigo: number; nome: string }> = [
            ...fetchedItems,
          ];
          setClients([
            ...items.map(item => ({
              label: item.nome,
              value: `${item.codigo}`,
            })),
          ]);
        }
      }
      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onSearchEmployee = async (pStrSearch: string) => {
    try {
      setInLoad(true);
      const response = await api.post('/employee/show', {
        filter: pStrSearch,
      });
      const { data } = response;
      if (data) {
        const { data: fetchedItems } = data;
        if (fetchedItems && fetchedItems.length) {
          const items: Array<{ codigo: number; nome: string }> = [
            ...fetchedItems,
          ];
          setEmployees([
            ...items.map(item => ({
              label: item.nome,
              value: `${item.codigo}`,
            })),
          ]);
        }
      }
      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const onSearchTypes = async (pStrSearch: string) => {
    try {
      setInLoad(true);
      const response = await api.post('/sales/types', {
        filter: pStrSearch,
      });
      
      const { data } = response;
      if (data && data.length) {
        const items: Array<{ codigo: number; nome: string }> = [...data];
        setTypes([
          ...items.map(item => ({ label: item.nome, value: `${item.codigo}` })),
        ]);
      }
      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const submitFilters = (data: FormDataProps) => {
    onApplyFilters({
      ...data,
      cliente: client ? client.value : null,
      funcionario: employee ? employee.value : null,
      tipo: type ? type.value : null,
      dtinicial: dtInicial ? `${dtInicial.format('YYYY-MM-DD')}` : null,
      dtfinal: dtFinal ? `${dtFinal.format('YYYY-MM-DD')}` : null,
    });
    closeFilters();
  };

  const resetFilters = () => {
    // alert('resetar filtros');
  };

  return (
    <>
      {clientsVisible ? (
        <Finder
          title="Cliente"
          onClose={() => setClientsVisible(false)}
          onSearch={onSearchClient}
          onSelectItem={cItem => setClient(cItem)}
          items={clients}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      {employeesVisible ? (
        <Finder
          title="Funcionário"
          onClose={() => setEmployeesVisible(false)}
          onSearch={onSearchEmployee}
          onSelectItem={cItem => setEmployee(cItem)}
          items={employees}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      {typeVisible ? (
        <Finder
          title="Tipo"
          onClose={() => setTypeVisible(false)}
          onSearch={onSearchTypes}
          onSelectItem={cItem => setType(cItem)}
          items={types}
          inLoad={inLoad}
          isSearch
        />
      ) : null}

      <Content visible={visible}>
        <Header>
          <Close onClick={closeFilters}>
            <BsArrowUpSquareFill size={32} color="#fff" />
          </Close>
          <h1>Filtros</h1>
        </Header>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              setSubmitting(false);
              submitFilters(values);
            }, 500);
          }}
          validationSchema={schema}
        >
          {({ submitForm, isSubmitting }) => (
            <Form style={{ width: '100%', maxWidth: '60rem' }}>
              <Group>
                <Stack flexDirection={'column'} width={'100%'} mb={'1.5rem'}>
                  <DatePicker
                    label="Data Inicial"
                    value={dtInicial}
                    onChange={newValue => setDtinicial(newValue)}
                    sx={{
                      input: { color: '#fff' },
                      label: { color: '#fff' },
                      svg: { fill: '#fff' },
                    }}
                  />
                </Stack>

                <Space />

                <Stack flexDirection={'column'} width={'100%'} mb={'3rem'}>
                  <DatePicker
                    label="Data Final"
                    value={dtFinal}
                    onChange={newValue => setDtfinal(newValue)}
                    sx={{
                      input: { color: '#fff' },
                      label: { color: '#fff' },
                      svg: { fill: '#fff' },
                    }}
                  />
                </Stack>
              </Group>

              <Group>
                <Stack flexDirection={'row'} width={'100%'} mb={'1.5rem'}>
                  <Docket
                    label="Funcionário"
                    value={employee?.label}
                    onClick={() => setEmployeesVisible(!employeesVisible)}
                    onClear={() => setEmployee(null)}
                  />
                </Stack>

                <Space />

                <Stack flexDirection={'row'} width={'100%'} mb={'1.5rem'}>
                  <Docket
                    label="Cliente"
                    value={client?.label}
                    onClick={() => setClientsVisible(!clientsVisible)}
                    onClear={() => setClient(null)}
                  />
                </Stack>

                <Space />

                <Stack flexDirection={'row'} width={'100%'} mb={'1.5rem'}>
                  <Docket
                    label="Tipo"
                    value={type?.label}
                    onClick={() => setTypeVisible(!typeVisible)}
                    onClear={() => setType(null)}
                  />
                </Stack>
              </Group>

              <Stack
                flexDirection={'row'}
                width={'100%'}
                justifyContent={'space-between'}
                marginTop={'2rem'}
              >
                <Clear onClick={resetFilters}>Resetar</Clear>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  disableElevation
                  onClick={submitForm}
                  variant="contained"
                  size="large"
                  style={{
                    fontSize: '1.4rem',
                    fontWeight: '700',
                    paddingTop: '1rem',
                    paddingBottom: '1rem',
                    background: COLORS.BUTTON_BG,
                  }}
                >
                  FILTRAR
                </Button>
              </Stack>

              {isSubmitting && <Loading />}
            </Form>
          )}
        </Formik>
      </Content>
      {visible ? (
        <Footer>
          <Dismiss onClick={closeFilters}>
            <BiSolidUpArrow size={18} color="#fff" />
            <span>Fechar</span>
          </Dismiss>
        </Footer>
      ) : null}
    </>
  );
};

export default Filtros;
