import * as yup from 'yup';

const schema = yup.object({
  nome: yup.string().required('Informe o Nome'),
  login: yup.string().required('Informe o Login'),
  senha: yup
    .string()
    .required('Informe a senha')
    .min(3, 'A senha no mínimo 3 dígitos'),
  confirmarSenha: yup
    .string()
    .required('Informe a senha')
    .min(3, 'A senha no mínimo 3 dígitos')
    .oneOf([yup.ref('senha')], 'Senhas não conferem'),
});

export default schema;
