import styled from 'styled-components';

import { ContentProps } from './interface';

export const Content = styled.div<ContentProps>`
  width: 100%;
  float: left;
  padding: 2rem 1.5rem;
  background: ${({ theme }) => theme.COLORS.FILTERS_BG};
  border-radius: 5px;
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

  @media (max-width: 1024px) {
  }
`;

export const Header = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  margin: 0 0 3rem 0;
  align-items: center;
  h1 {
    font-size: 1.4rem;
    color: #fff;
  }

  @media (max-width: 1024px) {
  }
`;

export const Group = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const Space = styled.div`
  width: 2rem;
  float: left;
  height: 4rem;

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Discart = styled.button.attrs({ type: 'button' })`
  background: none;
  font-size: 1rem;
  margin: 0 0 0 0.5rem;

  @media (max-width: 1024px) {
  }
`;

export const Close = styled.button.attrs({ type: 'button' })`
  background: none;
  font-size: 1rem;
  color: #ccc;
  margin: 0 1rem 0 0;

  @media (max-width: 1024px) {
  }
`;

export const Clear = styled.button.attrs({ type: 'button' })`
  background: none;
  font-size: 1rem;
  color: #ccc;
  margin: 0 1rem 0 0;

  @media (max-width: 1024px) {
  }
`;

export const Footer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem 0 2rem 0;

  @media (max-width: 1024px) {
  }
`;

export const Dismiss = styled.button.attrs({ type: 'button' })`
  float: left;
  align-self: center;
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-radius: 0.4rem;
  padding: 0.5rem 1rem;

  span {
    margin-left: 0.5rem;
    font-size: 1rem;
  }

  @media (max-width: 1024px) {
  }
`;
