import React, { useState, useEffect } from 'react';

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

import { useParams, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Button, Stack, InputAdornment } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';

import { toast } from 'react-toastify';

import api from '~/services/api';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  Loading,
  Area,
  Checkbox,
} from '~/components';

import { SubMenu, SubMenuBtn } from '../Empresa/styles';

import { FormDataProps } from './interface';

import schema from './schema';

const Usuarios: React.FC = () => {
  const { COLORS } = useTheme();

  const { id, empresa } = useParams<{ id: string; empresa: string }>();

  const navigate = useNavigate();

  const [inLoad, setInLoad] = useState(false);

  const [passwordVisible, setPasswordVisible] = useState(false);

  const [initialValues, setInitialValues] = useState<FormDataProps>({
    nome: '',
    login: '',
    senha: '',
    confirmarSenha: '',
  });

  const [ativo, setAtivo] = useState(false);

  const handlerSignUp = (formData: FormDataProps) => {
    return new Promise((resolve, reject) => {
      const handler = async () => {
        try {
          const responseEmpresa = await api.get(`/companies/${empresa}`);

          const { data: dataEmpresa } = responseEmpresa;

          if (dataEmpresa && dataEmpresa.length) {
            const { idempresa } = dataEmpresa[0];

            const response = await api[id !== '0' ? 'put' : 'post'](
              `/users${id !== '0' ? `/${id}` : ''}`,
              {
                idempresa: idempresa,
                nome: formData.nome,
                login: formData.login,
                senha: formData.senha,
                ativo: ativo ? 1 : 0,
              },
              {
                headers: {
                  idempresa: idempresa,
                },
              },
            );

            const { data } = response;

            if (!data || (Array.isArray(data) && !data.length)) {
              toast.error(`Credenciais inválidas!`);
              return resolve(true);
            }

            toast.success(`Usuário salvo com sucesso!`);
          }

          resolve(true);
        } catch (e) {
          toast.error(`${(e as Error).message}`);
          resolve(true);
        }
      };
      handler();
    });
  };

  useEffect(() => {
    const fetchUser = async (id: string) => {
      try {
        setInLoad(true);

        const responseEmpresa = await api.get(`/companies/${empresa}`);

        const { data: dataEmpresa } = responseEmpresa;

        if (dataEmpresa && dataEmpresa.length) {
          const { idempresa } = dataEmpresa[0];

          const response = await api.get(`/users/${id}`, {
            headers: {
              idempresa: idempresa,
            },
          });

          const { data } = response;

          if (data && data.length) {
            const cData = data[0];
            if (cData) {
              const { nome, ativo, login } = cData;

              setInitialValues({
                nome: nome,
                login: login,
                senha: '',
                confirmarSenha: '',
              });
              setAtivo(!!ativo);
            }
          }
        }

        setInLoad(false);
      } catch (e) {
        setInLoad(false);
      }
    };

    if (id && id !== '0') {
      fetchUser(id);
    }
  }, [id, empresa]);

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Usuários" />
          <Breadcrumb title="Usuários" />

          <Area>
            <SubMenu>
              <SubMenuBtn onClick={() => navigate(`/empresa/${empresa}`)}>
                DADOS DA EMPRESA
              </SubMenuBtn>
              <SubMenuBtn
                onClick={() => navigate(`/usuarios/${empresa}`)}
                className="active"
              >
                USUÁRIOS DA EMPRESA
              </SubMenuBtn>
            </SubMenu>
          </Area>

          {inLoad ? (
            <Loading />
          ) : (
            <Area>
              <Formik
                initialValues={initialValues}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                  await handlerSignUp(values);
                  if (id === '0') {
                    resetForm();
                  }
                  setSubmitting(false);
                }}
                validationSchema={schema}
              >
                {({ submitForm, isSubmitting }) => (
                  <Form style={{ width: '100%' }}>
                    <Stack
                      flexDirection={'column'}
                      width={'100%'}
                      mb={'1.5rem'}
                    >
                      <Field
                        component={TextField}
                        label="Nome"
                        name="nome"
                        type="text"
                        variant="outlined"
                      />
                    </Stack>

                    <Stack
                      flexDirection={'column'}
                      width={'100%'}
                      mb={'1.5rem'}
                    >
                      <Field
                        component={TextField}
                        label="Login"
                        name="login"
                        type="text"
                        variant="outlined"
                      />
                    </Stack>

                    <Stack
                      flexDirection={'column'}
                      width={'100%'}
                      mb={'1.5rem'}
                    >
                      <Field
                        component={TextField}
                        label="Senha"
                        name="senha"
                        type={passwordVisible ? 'text' : 'password'}
                        variant="outlined"
                        InputProps={{
                          placeholder: 'Senha',

                          endAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ outline: 'none' }}
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              {passwordVisible ? (
                                <AiFillEye size={22} color="#fff" />
                              ) : (
                                <AiFillEyeInvisible size={22} color="#fff" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>

                    <Stack
                      flexDirection={'column'}
                      width={'100%'}
                      mb={'1.5rem'}
                    >
                      <Field
                        component={TextField}
                        label="Confirme a senha"
                        name="confirmarSenha"
                        type={passwordVisible ? 'text' : 'password'}
                        variant="outlined"
                        InputProps={{
                          placeholder: 'Senha',

                          endAdornment: (
                            <InputAdornment
                              position="start"
                              style={{ outline: 'none' }}
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                            >
                              {passwordVisible ? (
                                <AiFillEye size={22} color="#fff" />
                              ) : (
                                <AiFillEyeInvisible size={22} color="#fff" />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Stack>

                    <Stack
                      flexDirection={'row'}
                      width={'100%'}
                      mb={'2rem'}
                      justifyContent={'flex-start'}
                    >
                      <Checkbox
                        onClick={() => setAtivo(!ativo)}
                        active={ativo}
                        label="Ativo?"
                      />
                    </Stack>

                    <Stack
                      flexDirection={'row'}
                      width={'100%'}
                      justifyContent={'flex-end'}
                    >
                      <Button
                        color="primary"
                        disabled={isSubmitting}
                        disableElevation
                        onClick={submitForm}
                        variant="contained"
                        type="submit"
                        size="large"
                        style={{
                          fontSize: '1.4rem',
                          fontWeight: '700',
                          paddingTop: '1rem',
                          paddingBottom: '1rem',
                          background: COLORS.BUTTON_BG,
                        }}
                      >
                        SALVAR
                      </Button>
                    </Stack>

                    {isSubmitting && <Loading />}
                  </Form>
                )}
              </Formik>
            </Area>
          )}
        </Container>
      </Gradient>
    </>
  );
};

export default Usuarios;
