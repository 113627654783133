import React from 'react';

import { Container } from './styles';

import { ListProps } from './interface';

const List: React.FC<ListProps> = ({ children }) => {
  return <Container>{children}</Container>;
};

export default List;
