import { createTheme } from '@mui/material/styles';

const THEME = createTheme({
  typography: {
    fontFamily: `"Inter", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
        },
      },
    },

    MuiTextField: {
      defaultProps: {
        color: 'primary',
        inputProps: {
          style: {
            color: '#fff',
            borderColor: '#fff',
            fontWeight: '700',
            fontSize: '1.2rem',
          },
        },
      },
      styleOverrides: {
        root: {
          '& .Mui-disabled': {
            color: '#fff',
            borderColor: '#fff',
            fontWeight: '700',
            fontSize: '1.2rem',
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: '#fff',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1.2rem',
          color: '#fff',
          '&.Mui-disabled': {
            color: '#fff',
          },
        },
      },
    },

    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiButtonBase-root': {
            color: '#fff',
            borderColor: 'rgba(255,255,255,0.4)',
            fontSize: '1.2rem',
            width: '3rem',
            height: '3rem',
          },
          '& .MuiPaginationItem-root': {
            color: '#fff',
            borderColor: 'rgba(255,255,255,0.4)',
          },
          '& .Mui-selected': {
            color: '#000',
            background: 'rgba(255,255,255,0.6)',
          },
        },
      },
    },
  },
});

export default THEME;
