import React, { useState, useEffect } from 'react';

import {
  MdOutlineKeyboardDoubleArrowLeft,
  MdOutlineKeyboardDoubleArrowRight,
} from 'react-icons/md';

import { Content } from './styles';

import Finder from '../Finder';

import { PaginationProps } from './interface';

import { ItemProps } from '../Finder/interface';

const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  onChangePage,
  totalPages,
  totalRegisters,
}) => {
  const [pages, setPages] = useState<ItemProps[]>([]);
  const [pagesVisible, setPagesVisible] = useState(false);

  const nextPage = () => {
    let next = currentPage + 1;
    if (next >= totalPages) {
      next = totalPages;
    }
    onChangePage(next);
  };

  const prevPage = () => {
    let prev = currentPage - 1;
    if (prev <= 1) {
      prev = 1;
    }
    onChangePage(prev);
  };

  const onSelectItem = (cItem: ItemProps) => {
    onChangePage(Number(cItem.value));
  };

  const onOpenPageModal = () => {
    setPagesVisible(true);
  };

  useEffect(() => {
    let cPage;
    const items: ItemProps[] = [];
    for (cPage = 1; cPage <= totalPages; cPage++) {
      items.push({
        label: `${cPage}`,
        value: `${cPage}`,
      });
    }
    setPages(items);
  }, [totalPages]);

  return (
    <>
      {pagesVisible ? (
        <Finder
          title="Selecione a Página:"
          onClose={() => setPagesVisible(false)}
          onSelectItem={onSelectItem}
          items={pages}
        />
      ) : null}
      <Content>
        <main>
          <p onClick={onOpenPageModal}>
            <strong>
              <span>{currentPage}</span> de <ins>{totalPages}</ins> Páginas
            </strong>
            <small>{totalRegisters} Registros</small>
          </p>
          <div>
            <button type="button" onClick={prevPage}>
              <MdOutlineKeyboardDoubleArrowLeft size={28} color="#fff" />
            </button>
            <button type="button" onClick={nextPage}>
              <MdOutlineKeyboardDoubleArrowRight size={28} color="#fff" />
            </button>
          </div>
        </main>
      </Content>
    </>
  );
};

export default Pagination;
