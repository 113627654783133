import styled from 'styled-components';

export const Content = styled.div`
  float: left;
  width: 100%;
  background: ${({ theme }) => theme.COLORS.FINDER_BG};
  display: flex;
  flex-direction: column;
  padding: 0px;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  z-index: 3100;
  overflow-y: auto;

  @media (max-width: 1024px) {
    overflow-x: hidden;
  }
`;

export const Header = styled.div`
  float: left;
  background: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.1);
  width: 100%;
  float: left;
  align-items: center;

  h1 {
    color: ${({ theme }) => theme.COLORS.FINDER_TEXT};
    font-size: 1.6rem;
  }

  @media (max-width: 1024px) {
  }
`;

export const SearchBar = styled.div`
  float: left;
  padding: 1.5rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.1);
  width: 100%;
  float: left;

  @media (max-width: 1024px) {
  }
`;

export const Search = styled.form`
  float: left;
  display: flex;
  flex-direction: row;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  padding: 1rem;
  width: 100%;
  float: left;

  div {
    display: flex;
    flex-direction: row;
    flex: 1;

    button {
      margin-left: -2rem;
    }
  }

  @media (max-width: 1024px) {
  }
`;

export const Input = styled.input`
  float: left;
  background: none;
  border: none;
  font-size: 1rem;
  width: 100%;
  color: ${({ theme }) => theme.COLORS.FINDER_TEXT};
  font-weight: 700;

  @media (max-width: 1024px) {
  }
`;

export const Button = styled.button`
  float: left;
  background: none;

  @media (max-width: 1024px) {
  }
`;

export const List = styled.div`
  float: left;
  background: none;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
  }
`;

export const Item = styled.div`
  float: left;
  background: none;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem;

  span {
    flex: 1;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.COLORS.FINDER_TEXT};
  }

  @media (max-width: 1024px) {
  }
`;
