import styled from 'styled-components';

export const Content = styled.div`
  float: left;
  width: 100%;
  background: ${({ theme }) => theme.COLORS.BREADCRUMB_BG};
  display: flex;
  /* flex-direction: row; */
  padding: 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 1px 1px 5px rgba(255, 255, 255, 0.1);
  border-radius: 0 0 5px 5px;
  padding: 1.5rem 1.5rem;

  h1 {
    font-size: 2rem;
    color: #fff;
    margin: 0 0 0rem 0;
  }

  main {
    width: 100%;
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 60rem;
    margin-top: 1rem;

    order: 1px solid #f5f;
  }

  @media (max-width: 1024px) {
    padding: 1rem;

    h1 {
      display: none;
    }

    main {
      max-width: 100%;

      padding: 0 1rem 1rem 1rem;
    }

    overflow-x: hidden;
  }
`;

export const Search = styled.form`
  float: left;
  display: flex;
  flex: 1;
  flex-direction: row;
  border: 2px solid rgba(255, 255, 255, 0.2);
  margin: 0 1rem 0 0;
  border-radius: 1rem;
  padding: 1rem;

  div {
    display: flex;
    flex-direction: row;
    flex: 1;

    button {
      margin-left: -2rem;
    }
  }

  @media (max-width: 1024px) {
  }
`;

export const Input = styled.input`
  float: left;
  background: none;
  border: none;
  font-size: 1rem;
  width: 100%;
  color: #fff;
  font-weight: 700;

  @media (max-width: 1024px) {
  }
`;

export const Button = styled.button`
  float: left;
  background: none;
  margin-left: 2rem;

  @media (max-width: 1024px) {
  }
`;

export const ButtonVoltar = styled.button`
  float: left;
  margin-left: 4rem;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.3rem;
  border-radius: 0.2rem;

  @media (max-width: 1024px) {
  }

  color: #fff;
  font-size: 0.8rem;
  min-width: 4rem;
`;
