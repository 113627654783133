import styled from 'styled-components';

export const Content = styled.div`
  float: left;
  border: 1px solid #fff;
  border-radius: 0.2rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem;

    small {
      color: #fff;
      opacity: 0.6;
      font-size: 0.8rem;
    }

    strong {
      font-size: 1.2rem;
      font-weight: normal;
      color: #fff;
    }
  }

  button {
    background: none;
    margin-right: 1rem;
  }

  @media (max-width: 1024px) {
    overflow-x: hidden;
  }
`;
