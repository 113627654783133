import styled from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.td<ContainerProps>`
  padding: 1rem;
  font-size: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.1);

  font-weight: 400;
  width: ${props => (props.width ? `${props.width}px !important` : 'auto')};
  color: ${({ theme, color }) => (color ? color : theme.COLORS.GRID_ITEM_TEXT)};

  &:first-child {
    border: none;
  }

  strong {
    color: ${({ theme, color }) =>
      color ? color : theme.COLORS.GRID_ITEM_TEXT_BOLD};
    font-weight: 700;
  }
`;
