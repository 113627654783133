import React, { useState, useCallback, useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import api from '~/services/api';

import {
  Gradient,
  Container,
  Menu,
  Breadcrumb,
  List,
  Card,
  Loading,
  Pagination,
  Mode,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
  Plus,
  Area,
} from '~/components';

import { SubMenu, SubMenuBtn } from '../Empresa/styles';

import Client from '~/@types/Client';

import qs from 'qs';

const Usuarios: React.FC = () => {
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const [inLoad, setInLoad] = useState(false);

  const [listType, setListType] = useState('card');

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRegisters, setTotalRegisters] = useState(0);

  const [pageTitle, setPageTitle] = useState('Usuários');

  const [items, setItems] = useState<Client[]>([]);

  const onChangePage = (cPage: number) => {
    setItems([]);
    fetchResults(cPage);
  };

  const fetchResults = useCallback(
    async (cPage: number) => {
      try {
        setCurrentPage(cPage);

        if (cPage === 1) {
          setTotalPages(0);
          setTotalRegisters(0);
          setItems([]);
        }

        setInLoad(true);

        const responseEmpresa = await api.get(`/companies/${id}`);

        const { data: dataEmpresa } = responseEmpresa;

        if (dataEmpresa && dataEmpresa.length) {
          const { idempresa } = dataEmpresa[0];

          let params = null;
          params = qs.stringify({
            page: cPage,
            perPage: 10,
            idempresa: idempresa,
          });

          setPageTitle(`Usuários da Empresa [${idempresa}]`);

          const response = await api.get(
            `/users${params ? `?${params}` : ''}`,
            {
              headers: {
                idempresa: idempresa,
              },
            },
          );

          const { data } = response;

          const { data: items, total, pages } = data;
          if (items && items.length) {
            setItems(items);
            setTotalRegisters(total);
            setTotalPages(pages);
          }
        }

        setInLoad(false);
      } catch (e) {
        setInLoad(false);
      }
    },
    [id],
  );

  useEffect(() => {
    fetchResults(1);
  }, [fetchResults]);

  return (
    <>
      <Gradient>
        <Container>
          <Plus onClick={() => navigate(`/usuario/${id}/0`)} />

          <Menu title={pageTitle} />
          <Breadcrumb title={pageTitle} />

          <Area>
            <SubMenu>
              <SubMenuBtn onClick={() => navigate(`/empresa/${id}`)}>
                DADOS DA EMPRESA
              </SubMenuBtn>
              <SubMenuBtn className="active">USUÁRIOS DA EMPRESA</SubMenuBtn>
            </SubMenu>
          </Area>

          {inLoad && <Loading />}

          {items.length ? (
            <Mode mode={listType} changeMode={mode => setListType(mode)} />
          ) : null}

          {listType === 'card' ? (
            <List>
              {items.map(item => (
                <Card
                  onClick={() => navigate(`/usuario/${id}/${item.id}`)}
                  key={item.id}
                  lines={[
                    [
                      {
                        label: 'Código:',
                        value: `${item.id}`,
                      },
                      {
                        label: 'Ativo:',
                        value: `${item.ativo === '1' ? 'Sim' : 'Não'}`,
                      },
                    ],
                    [
                      {
                        label: 'Nome:',
                        value: `${item.nome}`,
                      },
                    ],
                  ]}
                />
              ))}
            </List>
          ) : (
            <>
              {items.length ? (
                <Grid>
                  <GridLine stick>
                    <GridHeadItem title="Código" width={100} />
                    <GridHeadItem title="Nome" />
                    <GridHeadItem title="Ativo" />
                  </GridLine>

                  {items.map(item => (
                    <GridLine
                      key={item.id}
                      onClick={() => navigate(`/usuario/${id}/${item.id}`)}
                    >
                      <GridItem width={100}>
                        <small>{item.id}</small>
                      </GridItem>
                      <GridItem>
                        <strong>{item.nome}</strong>
                      </GridItem>
                      <GridItem>{item.ativo === '1' ? 'Sim' : 'Não'}</GridItem>
                    </GridLine>
                  ))}
                </Grid>
              ) : null}
            </>
          )}

          {totalPages > 1 ? (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              totalRegisters={totalRegisters}
              onChangePage={onChangePage}
            />
          ) : null}
        </Container>
      </Gradient>
    </>
  );
};

export default Usuarios;
