import React from 'react';

import { Routes, Route } from 'react-router-dom';

import RequireAuth from './RequireAuth';
import RequireAdmin from './RequireAdmin';

import Login from '~/pages/Login';

import Dashboard from '~/pages/Dashboard';
import Produtos from '~/pages/Produtos';
import Movimentacao from '~/pages/Movimentacao';
import ContasAReceber from '~/pages/ContasAReceber';
import ContasAPagar from '~/pages/ContasAPagar';
import Caixa from '~/pages/Caixa';
import MinhaConta from '~/pages/MinhaConta';
import Comparativo from '~/pages/Comparativo';

import DetalhesDaMovimentacao from '~/pages/DetalhesDaMovimentacao';
import DetalhesDoCaixa from '~/pages/DetalhesDoCaixa';

import Admin from '~/pages/Admin';
import Empresas from '~/pages/Empresas';
import Empresa from '~/pages/Empresa';

import Usuarios from '~/pages/Usuarios';
import Usuario from '~/pages/Usuario';

const AllRoutes: React.FC = () => (
  <Routes>
    <Route path="/trial" element={<Login trial={true} />} />
    <Route path="/" element={<Login />} />
    <Route path="/login" element={<Login />} />

    <Route
      path="/dashboard"
      element={
        <RequireAuth>
          <Dashboard />
        </RequireAuth>
      }
    />

    <Route
      path="/comparativo"
      element={
        <RequireAuth>
          <Comparativo />
        </RequireAuth>
      }
    />

    <Route
      path="/produtos"
      element={
        <RequireAuth>
          <Produtos />
        </RequireAuth>
      }
    />

    <Route
      path="/movimentacao"
      element={
        <RequireAuth>
          <Movimentacao />
        </RequireAuth>
      }
    />

    <Route
      path="/movimentacao/:id"
      element={
        <RequireAuth>
          <DetalhesDaMovimentacao />
        </RequireAuth>
      }
    />

    <Route
      path="/contas-a-receber"
      element={
        <RequireAuth>
          <ContasAReceber />
        </RequireAuth>
      }
    />

    <Route
      path="/contas-a-pagar"
      element={
        <RequireAuth>
          <ContasAPagar />
        </RequireAuth>
      }
    />

    <Route
      path="/caixa"
      element={
        <RequireAuth>
          <Caixa />
        </RequireAuth>
      }
    />

    <Route
      path="/caixa/:id"
      element={
        <RequireAuth>
          <DetalhesDoCaixa />
        </RequireAuth>
      }
    />

    <Route
      path="/minha-conta"
      element={
        <RequireAuth>
          <MinhaConta />
        </RequireAuth>
      }
    />

    <Route path="/painel" element={<Admin />} />

    <Route
      path="/empresas"
      element={
        <RequireAdmin>
          <Empresas />
        </RequireAdmin>
      }
    />

    <Route
      path="/empresa/:id"
      element={
        <RequireAdmin>
          <Empresa />
        </RequireAdmin>
      }
    />

    <Route
      path="/usuarios/:id"
      element={
        <RequireAuth>
          <Usuarios />
        </RequireAuth>
      }
    />

    <Route
      path="/usuario/:empresa/:id"
      element={
        <RequireAuth>
          <Usuario />
        </RequireAuth>
      }
    />
  </Routes>
);

export default AllRoutes;
