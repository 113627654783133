import React, { useEffect, useState } from 'react';

import api from '~/services/api';
import { format, addDays } from 'date-fns';
import { useAuth } from '~/hooks/auth';

import {
  Gradient,
  Container,
  Menu,
  Grid,
  GridHeadItem,
  GridItem,
  GridLine,
  Loading,
} from '~/components';

import { Content, Info, Area, Line, Card, Space, Totalizator } from './styles';

import Graph1 from './Graph1';
// import Graph2 from './Graph2';
// import Graph3 from './Graph3';
import Graph4 from './Graph4';

import { ChartProp } from './interface';
import { maskPrice } from '~/helpers/money';

const Dashboard: React.FC = () => {
  const { user } = useAuth();

  const [inLoad, setInLoad] = useState(true);
  const [months, setMonths] = useState<ChartProp[]>([]);
  const [days, setDays] = useState<ChartProp[]>([]);
  const [vendors, setVendors] = useState<ChartProp[]>([]);
  const [nexts, setNexts] = useState<ChartProp[]>([]);
  const [vend, setVend] = useState([]);

  const fetchIndicators = async () => {
    try {
      setInLoad(true);

      const response = await api.get(`/chart`);

      const { data } = response;
      const { diaData, mesData, vendasUlt7Dias, proximasFinancas } = data;

      setMonths(mesData);
      setDays(diaData);

      const dates: any = [];
      for (var i = 4; i >= 0; i--) {
        dates.push(format(addDays(new Date(), -i), 'yyyy-MM-dd'));
      }

      
      const vend: any = [];
      vendasUlt7Dias.forEach((item: any) => {
        if (!vend.includes(item.vendedor)) {
          vend.push(item.vendedor);
        }
      });
      setVend(vend);

      let auxVedors: any = [];
      vend.forEach((item: any) => {
        dates.forEach((data: any) => {
          const x = vendasUlt7Dias.filter((i: any) => String(i.dia) === String(data) && String(i.vendedor) === String(item));
          if (x[0]) {
            auxVedors.push({
              dia: data,
              vendedor: item,
              total: x[0].total
            });
          } else {
            auxVedors.push({
              dia: data,
              vendedor: item,
              total: '0.00'
            });
          }
        });
      });

      auxVedors = [
        ...auxVedors.map(
          (item: { vendedor: string; total: string; dia: string }) => ({
            label: `${item.vendedor}`,
            value: `${item.total}`,
            dia: `${item.dia}`,
          }),
        ),
      ];

      setVendors(auxVedors);

      setNexts([
        ...proximasFinancas.map(
          (item: { tipo: string; label: string; value: string }) => ({
            tipo: `${item.tipo}`,
            label: `${item.label}`,
            value: `${item.value}`,
          }),
        ),
      ]);

      setInLoad(false);
    } catch (e) {
      setInLoad(false);
    }
  };

  const fetchDays = (charts: ChartProp[]) => {
    return charts.reduce((acc: string[], item: ChartProp) => {
      if (item.dia) {
        if (!acc.includes(item.dia)) {
          acc.push(item.dia);
        }
      }
      return acc;
    }, []);
  };

  const fetchDayLabel = (dateMysql: string) => {
    const parts = dateMysql.split('-').reverse().join('/');
    return parts;
  };

  const fetchMonthLabel = (pMonth: string) => {
    const refs = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    const dataInMonth = pMonth.split('-');
    const monthRef = Number(dataInMonth[1]);
    return `${(refs[monthRef - 1].toUpperCase()).substring(0, 3)}`;
  };

  // const fetchDataForGraph = (pItems: ChartProp[]) => {
  //   const output: Array<{
  //     [key: string]: any;
  //   }> = [];
  //   const daysInData = fetchDays(pItems);
  //   daysInData.forEach(day => {
  //     let outputItem: {
  //       [key: string]: any;
  //     } = {
  //       mes: fetchDayLabel(day),
  //     };
  //     pItems
  //       .filter(cItem => cItem.dia === day)
  //       .forEach(cItem => {
  //         outputItem[cItem.label] = cItem.value;
  //       });

  //     output.push(outputItem);
  //   });

  //   return output;
  // };

  // const generateRandomColor = () => {
  //   let letters = '0123456789ABCDEF';
  //   let color = '#';
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  // const fetchLinesForGraph = (pItems: ChartProp[]) => {
  //   const output = pItems.reduce(
  //     (acc: Array<{ key: string; color: string }>, item: ChartProp, index) => {
  //       if (!acc.filter(cItem => cItem.key === item.label).length) {
  //         acc.push({
  //           color: `${generateRandomColor()}`,
  //           key: item.label,
  //         });
  //       }
  //       return acc;
  //     },
  //     [],
  //   );
  //   return output;
  // };

  const fetchDataForGraph4 = (items: ChartProp[]) => {
    const output: Array<{ Dia: string; Receber: number; Pagar: number }> = [];

    const daysInData = items.reduce((acc: string[], cItem: ChartProp) => {
      if (!acc.includes(cItem.label)) {
        acc.push(cItem.label);
      }
      return acc;
    }, []);

    daysInData.forEach(day => {
      let Receber = 0;
      let Pagar = 0;

      items.forEach(cItem => {
        if (cItem.label === day && cItem.tipo === 'Receber') {
          Receber = Number(cItem.value);
        }
        if (cItem.label === day && cItem.tipo === 'Pagar') {
          Pagar = Number(cItem.value);
        }
      });

      const partsInDay = day.split('-');
      const dia = `${partsInDay[2]}/${partsInDay[1]}`;

      output.push({
        Dia: dia,
        Receber,
        Pagar,
      });
    });

    return output;
  };

  const sumDays = (cDays: ChartProp[]) => {
    const _total = cDays
      .reduce((acc, item) => {
        acc += Number(item.value);
        return acc;
      }, 0)
      .toFixed(2);

    return `R$ ${maskPrice(_total)}`;
  };

  const sumMonths = (cMonths: ChartProp[]) => {
    const _total = cMonths
      .reduce((acc, item) => {
        acc += Number(item.value);
        return acc;
      }, 0)
      .toFixed(2);

    return `R$ ${maskPrice(_total)}`;
  };

  useEffect(() => {
    fetchIndicators();
  }, []);

  return (
    <>
      <Gradient>
        <Container>
          <Menu title="Dashboard" />
          {inLoad ? (
            <Loading />
          ) : (
            <Content>
              <Info>
                <h1>
                  Olá {user.userName}, bem vindo!
                </h1>
                <h2>Confira seus últimos indicadores.</h2>
              </Info>
              <Line>
                <Card>
                  <header>
                    <h2>Vendas Diárias</h2>
                    <span>Últimos 5 dias</span>
                  </header>
                  <Totalizator>
                    <h1>{sumDays(days)}</h1>
                    <small>Total de vendas dos ultimos 5 dias</small>
                  </Totalizator>
                </Card>
                <Space />
                <Area>
                  <Card>
                    <header>
                      <h2>Vendas Diárias</h2>
                      <span>Últimos 5 dias</span>
                    </header>

                    <Graph1
                      data={[
                        ...days.map(day => ({
                          data: day.label,
                          total: Number(day.value),
                        })),
                      ]}
                    />
                  </Card>
                </Area>
              </Line>

              <Line>
                <Card>
                  <header>
                    <h2>Vendas Mensais</h2>
                    <span>Últimos 5 meses</span>
                  </header>
                  <Totalizator>
                    <h1>{sumMonths(months)}</h1>
                    <small>Total de vendas dos ultimos 5 meses</small>
                  </Totalizator>
                </Card>
                <Space />
                <Area>
                  <Card>
                    <header>
                      <h2>Vendas Mensais</h2>
                      <span>Últimos 5 meses</span>
                    </header>

                    <Graph1
                      data={[
                        ...months.map(day => ({
                          data: fetchMonthLabel(day.label),
                          total: Number(day.value),
                        })),
                      ]}
                    />
                  </Card>
                </Area>
              </Line>

              {/* <Line>
                <Card>
                  <header>
                    <h2>Contas a pagar x Contas a receber</h2>
                  </header>

                  <Graph2 data={fetchDataForGraph2(nexts)} />
                </Card>
              </Line> */}

              <Line>
                <Card>
                  <header>
                    <h2>Contas a pagar x Contas a receber</h2>
                  </header>

                  <Graph4
                    data={fetchDataForGraph4(nexts)}
                  />
                </Card>
              </Line>

              <Line>
                <Card>
                  <header>
                    <h2>Ranking de Vendas (Últimos 5 dias)</h2>
                  </header>

                  {/* <Graph3
                    data={fetchDataForGraph(vendors)}
                    lines={fetchLinesForGraph(vendors)}
                  /> */}

                  <Grid>
                    <GridLine>
                      <GridHeadItem title="Vendedor" />
                      {fetchDays(vendors).map(day => <GridHeadItem title={fetchDayLabel(day)} />)}
                    </GridLine>

                    {vend.map(name => (
                      <>
                        <GridLine>
                          <GridItem>
                            <small>{name}</small>
                          </GridItem>
                          {vendors.filter(v => v.label === name).map(item => 
                            <GridItem>{maskPrice(item.value, true)}</GridItem>
                          )}
                        </GridLine>
                      </>
                    ))}
                  </Grid>
                </Card>
              </Line>

              <Line>
                <Card>
                  <header>
                    <h2>Vendas Mensais</h2>
                  </header>
                  <Grid>
                    <GridLine>
                      <GridHeadItem title="Mês" />
                      <GridHeadItem title="Total" />
                    </GridLine>
                    {months.map(month => (
                      <GridLine>
                        <GridItem>
                          <small>{format(addDays(new Date(month.label), -1), 'MM/yy')}</small>
                        </GridItem>
                        <GridItem>{maskPrice(month.value, true)}</GridItem>
                      </GridLine>
                    ))}
                  </Grid>
                </Card>
                <Space />
                <Card>
                  <header>
                    <h2>Vendas Diárias</h2>
                  </header>
                  <Grid>
                    <GridLine>
                      <GridHeadItem title="Mês" />
                      <GridHeadItem title="Total" />
                    </GridLine>

                    {days.map(day => (
                      <GridLine>
                        <GridItem>
                          <small>{day.label}</small>
                        </GridItem>
                        <GridItem>{maskPrice(day.value, true)}</GridItem>
                      </GridLine>
                    ))}
                  </Grid>
                </Card>
              </Line>
            </Content>
          )}
        </Container>
      </Gradient>
    </>
  );
};

export default Dashboard;
