import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  float: left;
  height: 14rem;
  padding: 0rem 1rem 0 0.5rem;

  @media (max-width: 1024px) {
    padding: 0;
  }
`;
