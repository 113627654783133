import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { MdArrowDropDownCircle, MdBusiness } from 'react-icons/md';
import { FaFileDownload, FaFileUpload, FaPiggyBank } from 'react-icons/fa';
import { BiSolidLogOutCircle } from 'react-icons/bi';
import { BsFillMenuButtonWideFill, BsGraphUpArrow } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { TbPackages } from 'react-icons/tb';
import { SiMoneygram } from 'react-icons/si';
import { RiAccountPinBoxFill } from 'react-icons/ri';
import { SlGraph } from 'react-icons/sl';

import { DarkModeToggle } from '@anatoliygatt/dark-mode-toggle';

import { useAuth } from '~/hooks/auth';
import { useAdmin } from '~/hooks/admin';

import logoImg from '~/assets/logo.png';

import {
  Container,
  Mobile,
  Content,
  Opts,
  OptsGroup,
  Opt,
  Footer,
  Close,
  Business,
} from './styles';

import { useTheme } from 'styled-components';

import { MenuProps } from './interface';

import themeControl from '~/hooks/theme';
import { ThemeState } from '~/hooks/theme/interface';

import Finder from '../Finder';

const Menu: React.FC<MenuProps> = ({ title }) => {
  const { COLORS } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { signOut, idempresa, setBusiness, user } = useAuth();
  const { token, signOut: signOutAdmin } = useAdmin();

  const { currentTheme, toggleTheme } = themeControl();

  const [businessVisible, setBusinessVisible] = useState(false);

  const [mode, setMode] = useState<ThemeState>(`${currentTheme}`);

  const ref = useRef<HTMLDivElement>(null);

  const [mobileMenuStatus, setMobileMenuStatus] = useState(false);

  const handlerMobileMenuVisibility = () => {
    setMobileMenuStatus(!mobileMenuStatus);
  };

  const checkPageIsActive = (page: string): boolean => {
    const data = location.pathname.split('/');
    return data[1] === page ? true : false;
  };

  const goToPage = (page: string): void => {
    navigate(`${page}`);
    setMobileMenuStatus(false);
  };

  const logout = (): void => {
    signOut();
    navigate(`/login`);
  };

  const logoutAdmin = () => {
    signOutAdmin();
    navigate(`/painel`);
  };

  const fetchBusinessName = (pIdempresa: string) => {
    return user.filiais.reduce((acc, filial) => {
      if (filial.idempresa === pIdempresa) {
        acc = filial.nome;
      }
      return acc;
    }, '');
  };

  const fetchBusinessType = (pIdempresa: string) => {
    if (pIdempresa === user.idempresa) {
      return 'Matriz';
    }
    return 'Filial';
  };

  const saveBusiness = (pIdempresa: string) => {
    setBusiness(pIdempresa);
    setTimeout(() => {
      window.location.reload();
    });
  };

  const handlerShortCuts = (event: KeyboardEvent): void => {
    if (mobileMenuStatus) {
      if (event.key === 'Escape') {
        setMobileMenuStatus(false);
      }
    }
  };

  const handleClickOutside = useCallback(
    (event: Event): void => {
      if (
        mobileMenuStatus &&
        ref.current &&
        !ref.current.contains(event.target as Node)
      ) {
        setMobileMenuStatus(false);
      }
    },
    [mobileMenuStatus],
  );

  useEffect(() => {
    document.addEventListener('keydown', handlerShortCuts, true);
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('keydown', handlerShortCuts, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <>
      {token !== 'isAdmin' && businessVisible && user && user.filiais.length > 1 ? (
        <Finder
          title="Empresa"
          onClose={() => setBusinessVisible(false)}
          onSelectItem={cItem => saveBusiness(cItem.value)}
          items={[
            ...user.filiais.map(filial => ({
              label: `${filial.nome}`,
              value: `${filial.idempresa}`,
            })),
          ]}
          isSearch
        />
      ) : null}

      <Container>
        <Mobile>
          <button type="button">
            <BsFillMenuButtonWideFill
              size={28}
              color="#fff"
              onClick={handlerMobileMenuVisibility}
            />
          </button>
          <h1>{title || 'Sisaut Gestor'}</h1>
          <div>
            <img
              src={logoImg}
              alt="Sisaut Gestor"
              title="Sisaut Gestor | 0.0.1"
              height="35px"
            />
          </div>
        </Mobile>

        <Content
          className={mobileMenuStatus ? 'menu-mobile-show' : 'menu-mobile-hide'}
        >
          <Opts ref={ref}>
            {token !== 'isAdmin' ? (
              <OptsGroup>
                <Opt
                  active={checkPageIsActive(`dashboard`)}
                  onClick={() => goToPage(`/dashboard`)}
                >
                  <i>
                    <BsGraphUpArrow size={18} color={COLORS.MENU_IC} />
                  </i>
                  <span>Dashboard</span>
                </Opt>

                {user && user.filiais.length > 1 ? (
                  <Opt
                    active={checkPageIsActive(`comparativo`)}
                    onClick={() => goToPage(`/comparativo`)}
                  >
                    <i>
                      <SlGraph size={18} color={COLORS.MENU_IC} />
                    </i>
                    <span>Comparativo</span>
                  </Opt>
                ) : null}

                <Opt
                  active={checkPageIsActive(`produtos`)}
                  onClick={() => goToPage(`/produtos`)}
                >
                  <i>
                    <TbPackages size={22} color={COLORS.MENU_IC} />
                  </i>
                  <span>Produtos</span>
                </Opt>

                <Opt
                  active={checkPageIsActive(`movimentacao`)}
                  onClick={() => goToPage(`/movimentacao`)}
                >
                  <i>
                    <SiMoneygram size={22} color={COLORS.MENU_IC} />
                  </i>
                  <span>Movimentação</span>
                </Opt>

                <Opt
                  active={checkPageIsActive(`contas-a-receber`)}
                  onClick={() => goToPage(`/contas-a-receber`)}
                >
                  <i>
                    <FaFileDownload size={22} color={COLORS.MENU_IC} />
                  </i>
                  <span>Contas a Receber</span>
                </Opt>

                <Opt
                  active={checkPageIsActive(`contas-a-pagar`)}
                  onClick={() => goToPage(`/contas-a-pagar`)}
                >
                  <i>
                    <FaFileUpload size={22} color={COLORS.MENU_IC} />
                  </i>
                  <span>Contas a Pagar</span>
                </Opt>

                <Opt
                  active={checkPageIsActive(`caixa`)}
                  onClick={() => goToPage(`/caixa`)}
                >
                  <i>
                    <FaPiggyBank size={22} color={COLORS.MENU_IC} />
                  </i>
                  <span>Caixa</span>
                </Opt>

                <Opt
                  active={checkPageIsActive(`minha-conta`)}
                  onClick={() => goToPage(`/minha-conta`)}
                >
                  <i>
                    <RiAccountPinBoxFill size={22} color={COLORS.MENU_IC} />
                  </i>
                  <span>Minha Conta</span>
                </Opt>

                <Opt onClick={logout}>
                  <i>
                    <BiSolidLogOutCircle size={22} color="#df5040" />
                  </i>
                  <span style={{ color: '#df5040' }}>Sair</span>
                </Opt>
              </OptsGroup>
            ) : (
              <OptsGroup>
                <Opt
                  active={
                    checkPageIsActive(`empresas`) ||
                    checkPageIsActive(`empresa`)
                  }
                  onClick={() => goToPage(`/empresas`)}
                >
                  <i>
                    <MdBusiness size={18} color={COLORS.MENU_IC} />
                  </i>
                  <span>Empresas</span>
                </Opt>

                <Opt onClick={logoutAdmin}>
                  <i>
                    <BiSolidLogOutCircle size={22} color="#df5040" />
                  </i>
                  <span style={{ color: '#df5040' }}>Sair</span>
                </Opt>
              </OptsGroup>
            )}

            {token !== 'isAdmin' && user && user.filiais.length > 1 ? (
              <Business onClick={() => setBusinessVisible(true)}>
                <div>
                  <span>Empresa:</span>
                  <strong>{fetchBusinessName(idempresa)}</strong>
                  <small>{fetchBusinessType(idempresa)}</small>
                </div>
                <MdArrowDropDownCircle size={28} color="#fff" />
              </Business>
            ) : null}

            <Footer>
              <img
                src={logoImg}
                alt="Sisaut Gestor"
                title="Sisaut Gestor | 0.0.1"
              />
              <h1>Sisaut Gestor</h1>
              <span>Versão: 0.0.1</span>

              <DarkModeToggle
                mode={mode}
                dark=""
                light=""
                size="sm"
                onChange={mode => {
                  setMode(mode);
                  toggleTheme();
                }}
                activeLabelColor="#ccc"
              />
            </Footer>
          </Opts>

          <Close onClick={() => setMobileMenuStatus(false)}>
            <AiOutlineClose size={36} color="#fff" />
          </Close>
        </Content>
      </Container>
    </>
  );
};

export default Menu;
