import React from 'react';

import {
  BarChart,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import { Container } from './styles';

import { Graph1Props } from './interface';
import { maskPrice } from '~/helpers/money';

const Graph2: React.FC<Graph1Props> = ({ data, lines }) => {
  return (
    <Container>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{
            top: 0,
            right: 10,
            left: 10,
            bottom: 25,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />

          <XAxis dataKey="mes" stroke={'#ccc'} angle={0} textAnchor="end" tickMargin={10} dx={30} />

          <Tooltip
            contentStyle={{
              borderRadius: 5,
              borderColor: '#fff',
            }}
            labelStyle={{ color: '#333' }}
            formatter={(value: number) => maskPrice(value, false, '', '%')}
            cursor={{fill: 'rgba(255, 255, 255, 0.08)'}}
          />

          {lines.map(line => (
            <Bar type="monotone" dataKey={line.key} fill={line.color} />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default Graph2;
