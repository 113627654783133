import React from 'react';

import { Container, Line, Item } from './styles';

import { CardProps } from './interface';

const Card: React.FC<CardProps> = ({ lines, ...props }) => {
  return (
    <Container {...props}>
      {lines.map(line => (
        <Line>
          {line.map(item => (
            <Item>
              <span>{item.label}</span>
              <strong>{item.value}</strong>
            </Item>
          ))}
        </Line>
      ))}
    </Container>
  );
};

export default Card;
