import React from 'react';

import { LinearProgress } from '@mui/material';
import { LoadingProps } from './interface';

import { Container } from './styles';

import { useTheme } from 'styled-components';

const Loading: React.FC<LoadingProps> = () => {
  const { COLORS } = useTheme();

  return (
    <Container>
      <LinearProgress
        color="primary"
        style={{
          height: '20px',
          borderRadius: '0.2rem',
          width: '100%',
        }}
        sx={{
          backgroundColor: COLORS.BAR_CONTAINER_BG,
          '& .MuiLinearProgress-bar': {
            backgroundColor: COLORS.BAR_PROGRESS_BG,
          },
        }}
      />
    </Container>
  );
};

export default Loading;
