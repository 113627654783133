import styled from 'styled-components';

import { ContainerProps } from './interface';

export const Container = styled.tr<ContainerProps>`
  transition: all 0.5s ease;

  position: ${props => (props.stick ? 'sticky' : 'inherit')};

  &:nth-child(odd) {
    background: rgba(255, 255, 255, 0.05);
  }

  &:hover {
    background: rgb(255 255 255 / 15%) !important;
  }

  td {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  &:last-child td {
    border-bottom: none;
  }
`;
